import { MdDashboard, MdOutlineMiscellaneousServices } from 'react-icons/md';
import { AiFillBank, AiOutlineApi, AiFillPicture } from 'react-icons/ai';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { RiSettings3Fill } from 'react-icons/ri';
import { CgUserList } from 'react-icons/cg';
import { BsClipboardMinus } from 'react-icons/bs';
import { IoIosLogOut } from 'react-icons/io';

export const Shortcuts = [
    {
        name: 'Dashboard',
        icon: <MdDashboard />,
        color: '#C3E88D',
        sub: [
            {
                subname: 'Dashboard',
                path: '/'
            }
        ]
    },
    {
        name: 'Users',
        icon: <CgUserList />,
        color: '#C792EA',
        sub: [
            {
                subname: '+Add-users/view',
                path: '/get-users',
                className: 'users',
                belong: 'users',
                item: [
                    {
                        name: 'View users',
                        path: '/get-users',
                        className: '/get-users',
                    },
                    {
                        name: '+Add user',
                        path: '/add-user',
                        className: '/add-user',
                    },
                ]
            },
            {
                subname: 'Customer group',
                path: '/customer-group',
                className: 'users',
                belong: 'users',
                item: [
                    {
                        name: 'Customer group',
                        path: '/customer-group',
                        className: '/customer-group',
                    }
                ]
            }
        ],
    },
    {
        name: 'Services',
        icon: <AiOutlineApi />,
        color: '#89DDFF',
        sub: [
            {
                subname: 'Service imei',
                path: '/get-services',
                className: 'services',
                belong: 'services',
                item: [
                    {
                        name: 'All services',
                        path: '/get-services',
                        className: '/get-services',
                    },
                    {
                        name: '+Add service',
                        path: '/add-service',
                        className: '/add-service',
                    },
                    {
                        name: 'Groups',
                        path: '/services/get-groups',
                        className: '/services/get-groups',
                    },
                ]
            },
            {
                subname: 'Service server',
                path: '/get-servers',
                className: 'services',
                belong: 'server',
                item: [
                    {
                        name: 'All servers',
                        path: '/get-servers',
                        className: '/get-servers',
                    },
                    {
                        name: '+Add server',
                        path: '/add-server',
                        className: '/add-server',
                    },
                    {
                        name: 'Groups',
                        path: '/servers/get-groups',
                        className: '/servers/get-groups',
                    },
                ]
            }
        ]
    },
    {
        name: 'Orders',
        icon: <BsClipboardMinus />,
        color: '#FFCB8B',
        sub: [
            {
                subname: 'Orders imei',
                path: '/get-orders',
                className: 'news',
                belong: 'orders',
                item: [
                    {
                        name: 'All orders',
                        path: '/get-orders',
                        className: '/get-orders',
                    },
                    {
                        name: 'Pending orders',
                        path: '/awaiting-orders',
                        className: '/awaiting-orders',
                    },
                    {
                        name: 'Answered orders',
                        path: '/answered-orders',
                        className: '/answered-orders',
                    },
                ]
            },
            {
                subname: 'Orders server',
                path: '/get-orders-server',
                className: 'orderServer',
                belong: 'orderServer',
                item: [
                    {
                        name: 'All orders',
                        path: '/get-orders-server',
                        className: '/get-orders-server',
                    },
                    {
                        name: 'Pending orders',
                        path: '/awaiting-orders-server',
                        className: '/awaiting-orders-server',
                    },
                    {
                        name: 'Answered orders',
                        path: '/answered-orders-server',
                        className: '/answered-orders-server',
                    },
                ]
            }
        ]
    },
    {
        name: 'Tickets',
        icon: <FaFileInvoiceDollar />,
        color: '#F78C6C',
        sub: [
            {
                subname: 'View/tickets',
                path: '/get-tickets',
                className: 'tickets',
                belong: 'tickets',
                item: [
                    {
                        name: 'View ticekts',
                        path: '/get-tickets',
                        className: '/get-tickets',
                    },
                ]
            }
        ]
    },
    {
        name: 'Banks',
        icon: <AiFillBank />,
        color: '#82AAFF',
        sub: [
            {
                subname: '+Add-banks/view',
                path: '/get-banks',
                className: 'banks',
                belong: 'banks',
                item: [
                    {
                        name: 'View banks',
                        path: '/get-banks',
                        className: '/get-banks',
                    },
                    {
                        name: '+Add bank',
                        path: '/add-bank',
                        className: '/add-bank',
                    },
                ]
            }
        ]
    },

    {
        name: 'Apis',
        icon: <MdOutlineMiscellaneousServices />,
        color: '#BFC7D5',
        sub: [
            {
                subname: '+Add-apis/view',
                path: '/get-apis',
                className: 'apis',
                belong: 'apis',
                item: [
                    {
                        name: 'View apis',
                        path: '/get-apis',
                        className: '/get-apis',
                    },
                    {
                        name: '+Add api',
                        path: '/add-api',
                        className: '/add-api',
                    },
                ]
            }
        ]
    },
    {
        name: 'Images',
        icon: <AiFillPicture />,
        color: '#FF5572',
        sub: [
            {
                subname: '+Add-images/view',
                path: '/get-images',
                className: 'images',
                belong: 'images',
                item: [
                    {
                        name: 'View images',
                        path: '/get-images',
                        className: '/get-images',
                    },
                    {
                        name: '+Add image',
                        path: '/add-image',
                        className: '/add-image',
                    },
                    {
                        name: 'Categories',
                        path: '/images/get-categories',
                        className: '/images/get-categories',
                    }
                ]
            }
        ]
    },
    {
        name: 'Settings',
        icon: <RiSettings3Fill />,
        color: 'blue',
        sub: [
            {
                subname: 'Option general',
                path: '/general-settings',
                className: 'settings',
                belong: 'settings',
                item: [
                    {
                        name: 'General',
                        path: '/general-settings',
                        className: '/general-settings',
                    }
                ]
            }
        ]
    },
    {
        name: '',
        icon: <IoIosLogOut />,
        color: 'blue',
        sub: [
            {
                subname: 'Sign out',
                path: '/',
                item: [
                    {
                        name: 'General',
                        path: '/general-settings',
                        className: '/general-settings',
                    }
                ]
            }
        ]
    },
]


export const Show = [
    {
        name: 'User',
        belong: 'user',
        className: '/get-users/user-info',
        path: '/get-users/user-info'
    },
    {
        name: 'Balance',
        belong: 'user',
        className: '/get-users/user-balance',
        path: '/get-users/user-balance'
    },
    {
        name: 'Api',
        belong: 'user',
        className: '/get-users/user-api',
        path: '/get-users/user-api'
    },
    {
        name: 'Tickets',
        belong: 'user',
        className: '/get-users/user-tickets',
        path: '/get-users/user-tickets'
    },
    {
        name: 'Orders',
        belong: 'user',
        className: '/get-users/user-orders',
        path: '/get-users/user-orders'
    },
    {
        name: 'Imei pricing',
        belong: 'customer',
        className: '/customer-group/imei-pricing',
        path: '/customer-group/imei-pricing'
    },
    {
        name: 'Server pricing',
        belong: 'customer',
        className: '/customer-group/server-pricing',
        path: '/customer-group/server-pricing'
    },
    {
        name: 'Category',
        belong: 'category',
        className: '/images/get-categories/category-info',
        path: '/images/get-categories/category-info'
    },
    {
        name: 'Api',
        belong: 'api',
        className: '/get-apis/api-info',
        path: '/get-apis/api-info'
    },
    {
        name: 'Imei service',
        belong: 'api',
        className: '/get-apis/api-services',
        path: '/get-apis/api-services'
    },
    {
        name: 'Server service',
        belong: 'api',
        className: '/get-apis/api-server',
        path: '/get-apis/api-server'
    },
    {
        name: 'Bank',
        belong: 'bank',
        className: '/get-banks/bank-info',
        path: '/get-banks/bank-info'
    },
    {
        name: 'Service',
        belong: 'service',
        className: '/get-services/service-info',
        path: '/get-services/service-info'
    },
    {
        name: 'Prices',
        belong: 'service',
        className: '/get-services/custom-prices',
        path: '/get-services/custom-prices'
    },
    {
        name: 'Show item',
        belong: 'service',
        className: '/get-services/show-item',
        path: '/get-services/show-item'
    },
    {
        name: 'Custom',
        belong: 'service',
        className: '/get-services/custom-service',
        path: '/get-services/custom-service'
    },
    {
        name: 'Api connect',
        belong: 'service',
        className: '/get-services/service-api',
        path: '/get-services/service-api'
    },
    {
        name: 'Server',
        belong: 'server',
        className: '/get-servers/server-info',
        path: '/get-servers/server-info'
    },
    {
        name: 'Prices',
        belong: 'server',
        className: '/get-servers/custom-prices',
        path: '/get-servers/custom-prices'
    },
    {
        name: 'Custom',
        belong: 'server',
        className: '/get-servers/custom-server',
        path: '/get-servers/custom-server'
    },
    {
        name: 'Api connect',
        belong: 'server',
        className: '/get-servers/server-api',
        path: '/get-servers/server-api'
    },
    {
        name: 'Ticket',
        belong: 'ticket',
        className: '/get-tickets/ticket-info',
        path: '/get-tickets/ticket-info'
    },
    {
        name: 'Order',
        belong: 'order',
        className: '/get-orders/order-info',
        path: '/get-orders/order-info'
    },
    {
        name: 'Order server',
        belong: 'order-server',
        className: '/get-orders-server/order-info',
        path: '/get-orders-server/order-info'
    },
]