import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Update_users } from "../../../../store/storeReducers/UsersReducer";
import { get_user, update_user } from "../../../../functions/Userfunction";
import { useOutletContext } from "react-router-dom";
import { SetTickets } from "../../../../store/storeReducers/TicketsReducer";
import { active } from "../../../../constants/status";
import { payments } from "../../../../constants/payments";
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";

const BalanceUser = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [loading, setLoad] = useState({
        update: false,
        line: false
    });
    const [error, setErr] = useState({ err: [] });
    const [ticket, setTicket] = useState(true);
    const [user, setUser] = useState({
        id: '',
        balance: '',
        amount: '',
        reason: '',
        isCredit: '',
        credits: '',
        credit_amount: '',
        status: '',
    });

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getUser(param.id);
        }
    }, [param.count]);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })

    }

    const toggleChange = (e) => {
        setTicket(e.target.checked);
    }


    const getUser = async (id) => {
        try {
            const res = await get_user({ id, action: 'balance' });
            if (res.data.user) {
                setUser(res.data.user);
                dispatch(Update_users(res.data.user));
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateUser = async (action) => {
        setLoad({ ...loading, update: true });
        try {
            const dataUser = { ...user, action, isTicket: ticket }
            const res = await update_user(dataUser);
            if (res.data) {
                dispatch(Update_users(res.data.user));
                setUser(res.data.user);
                alert(res.data.alert);
            }
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                } else if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }


    return (
        <div>
            {
                !line &&
                <table className="table-add balance" >
                    <tbody>
                        <tr>
                            <td className="fieldlabel">Balance:</td>
                            <td className="fieldlabel">
                                {
                                    ((parseFloat(user.balance) || 0).toFixed(2))
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">Action:</td>
                            <td className="center-objects pst-relative">
                                <input
                                    type='checkbox'
                                    value={4}
                                    name='status'
                                    onChange={(e) => handleChange(e)}
                                    checked={user.status ? user.status == 4 && true : ''}
                                />
                                <span className="pd-right_20">+Add</span>
                                <input
                                    type='checkbox'
                                    value={3}
                                    name='status'
                                    onChange={(e) => handleChange(e)}
                                    checked={user.status ? user.status == 3 && true : ''}
                                />
                                <span>-Reduce</span>
                                <div className="pst-absolute bottom-0" >
                                    <span className="color-red ft-size_11 ft-family-monospace">
                                        {error.status}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">Amount:</td>
                            <td className="fieldlabel">
                                <input
                                    className='outline-none pd-0-10p'
                                    defaultValue={user.amount ? user.amount : 0}
                                    name='amount'
                                    type="number"
                                    placeholder="Balance:"
                                    onChange={(e) => handleChange(e)}
                                />
                                <div >
                                    <span className="color-red ft-size_11 ft-family-monospace">
                                        {error.amount}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">Total:</td>
                            <td className="fieldlabel">
                                {
                                    user.status == 4 ?
                                        ((parseFloat(user.balance) + parseFloat(user.amount)) || 0).toFixed(2)
                                        :
                                        user.status == 3 ?
                                            ((parseFloat(user.balance) - parseFloat(user.amount)) || 0).toFixed(2)
                                            :
                                            (0).toFixed(2)
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className='fieldlabel'>Reason:</td>
                            <td className='fieldlabel'>
                                <select
                                    className='outline-none pd-0-10p'
                                    name='reason'
                                    onChange={(e) => handleChange(e)}
                                    value={user.reason}
                                >
                                    {
                                        payments.map((pay, key) => {
                                            return (
                                                <optgroup key={key} label={pay.name}>
                                                    {
                                                        pay.reason &&
                                                        pay.reason.map((p, k) => {
                                                            return (
                                                                <option key={k} value={p.value ? p.reason : ''} >
                                                                    {p.reason}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </optgroup>
                                            )
                                        })
                                    }
                                </select>
                                <div >
                                    <span className="color-red ft-size_11 ft-family-monospace">
                                        {error.reason}
                                    </span>
                                </div>
                            </td>

                        </tr>
                        <tr>
                            <td className="fieldlabel">Ticket:</td>
                            <td className="center-objects pst-relative">
                                <input
                                    type='checkbox'
                                    value={ticket}
                                    name='ticket'
                                    onChange={(e) => toggleChange(e)}
                                    checked={ticket}
                                />
                                <span className="pd-right_20">+Generate ticket</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">
                                <button
                                    disabled={loading.update}
                                    onClick={() => updateUser('balance')}
                                >
                                    {
                                        loading.update ?
                                            <div className="spin update"></div>
                                            :
                                            <div>Update</div>
                                    }
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td className="fieldlabel">On credit:</td>
                            <td className="fieldlabel">
                                <select
                                    className='outline-none pd-0-10p'
                                    name='isCredit'
                                    onChange={(e) => handleChange(e)}
                                    value={user.isCredit}
                                >
                                    {
                                        active.map((a, k) => {
                                            return (
                                                <option key={k} value={a.isActive}>
                                                    {a.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        {
                            user.isCredit == true &&
                            <>
                                <tr>
                                    <td className="fieldlabel">Credit Balance:</td>
                                    <td className="fieldlabel">
                                        {
                                            ((parseFloat(user.credits) || 0).toFixed(2))

                                        }
                                        <div >
                                            <span className="color-red ft-size_11 ft-family-monospace">
                                                {error.credits}
                                            </span>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="fieldlabel">Credit Amount:</td>
                                    <td className="fieldlabel">
                                        <input
                                            className='outline-none pd-0-10p'
                                            defaultValue={user.credit_amount ? user.credit_amount : 0}
                                            name='credit_amount'
                                            type="number"
                                            placeholder="Balance:"
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <div >
                                            <span className="color-red ft-size_11 ft-family-monospace">
                                                {error.credit_amount}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        }
                        <tr>
                            <td>
                                <button
                                    disabled={loading.update}
                                    onClick={() => updateUser('credits')}
                                >
                                    {
                                        loading.update ?
                                            <div className="spin update"></div>
                                            :
                                            <div>Save</div>
                                    }
                                </button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            }
        </div>
    )
}

export default BalanceUser