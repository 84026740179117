import React from 'react';
import { Shortcuts, Show } from '../Shortcuts';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

export const NavigateIndex = (props) => {

    const { belong, className, handleClass } = props
    return (
        <ul className='ul-in dp-flex list-none'>
            {
                Shortcuts.map((sub, k) => {
                    return (
                        <li className={`list-navi`} key={k}>
                            {
                                <a className='select'>
                                    {
                                        sub.sub.map((m, key) => {
                                            return (
                                                m.belong === belong && m.item &&
                                                m.item.map((i, k) => {
                                                    return (
                                                        <span onClick={() => handleClass(i)} className={className == i.className ? 'active' : ''} key={k} >
                                                            {
                                                                i.name
                                                            }
                                                        </span>
                                                    )
                                                })
                                            )
                                        })
                                    }
                                </a>
                            }
                        </li>
                    )
                })
            }
        </ul >
    )
}

export const NavIndex = (props) => {
    const navigate = useNavigate();
    const { path, belong, className, handleClass } = props

    return (
        <ul className='dp-flex ul-min list-none'>
            <div onClick={() => navigate(path)} className='btn-back cursor-pointer'>
                <RiArrowGoBackLine className='back' />
            </div>
            {
                Show.map((show, k) => {
                    return (
                        <li className={`list-navi`} key={k}>
                            {
                                show.belong === belong &&
                                <a className='select'>
                                    <span onClick={() => handleClass(show)} className={className == show.className ? 'active' : ''} >
                                        {
                                            show.name
                                        }
                                    </span>
                                </a>
                            }
                        </li>
                    )
                })
            }
        </ul >
    )
}