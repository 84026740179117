import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api } from '../../../api/ConnectApi';
import { AiOutlineEdit } from "react-icons/ai";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Paginator from '../../../components/Paginator';
import Search from '../../../components/Search';
import { useDispatch, useSelector } from 'react-redux';
import { SetCategories, Update_categories, Delete_categories } from '../../../store/storeReducers/CategoriesReducer';
import { get_categories } from '../../../functions/Imagesfunction';
import { update_Line } from '../../../store/storeReducers/LoadingReducer';
import { create_category, update_category, delete_category } from '../../../functions/Imagesfunction';
import ModalDelete from '../../../components/ModalDelete';

const GetCategories = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const categories = useSelector((state) => state.categories.categories.categories);
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState({
        add: false,
        update: false,
        delete: false
    });
    const [category, setCategory] = useState({
        id: '',
        name: ''
    });
    const [value, setValue] = useState({})

    useEffect(() => {
        dispatch(update_Line(true));
        getCategories()
    }, [])

    const getCategories = async (value) => {
        try {
            const category = {
                action: 'categories',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_categories(category);
            dispatch(SetCategories(res.data.categories));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }


    const addCateg = async () => {
        setLoad({ ...loading, add: true });
        try {
            const res = await create_category(category);
            alert(res.data.alert);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, add: false });
        await getCategories();
    }

    const updateCateg = async (value) => {
        setLoad({ ...loading, update: true });
        try {
            const res = await update_category(value);
            alert(res.data.alert);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const deleteCateg = async () => {
        setLoad({ ...loading, modal: false, delete: true });
        try {
            const res = await delete_category(value.id);
            dispatch(Delete_categories(value));
            alert(res.data.alert);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, delete: false });
    }

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        setCategory({
            ...category,
            [e.target.name]: e.target.value
        })
    }

    const toggleHandler = (item) => {
        let newArray = [...categories.data];
        newArray[item.index] = {
            id: newArray[item.index].id,
            name: item.e.target.value
        }
        dispatch(Update_categories(newArray[item.index]));
    };

    const _delete = (value) => {
        setValue(value);
        setLoad({ ...loading, modal: true });
    }

    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({ ...loading, modal: false })}
                action={() => deleteCateg()}
            />
            {
                !line &&
                <div>
                    <Search
                        action={(c) => getCategories(c)}
                        value={"Search by name..."}
                    />
                    <table className="table-add balance" >
                        <thead>
                            <tr>
                                <td className="fieldlabel">Creat new category:</td>
                                <td className="fieldlabel">
                                    <input
                                        className='outline-none pd-0-10p'
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.name}
                                        </span>
                                    </div>
                                </td>
                                <td className="fieldlabel">
                                    <button
                                        disabled={loading.add}
                                        onClick={() => addCateg()}
                                    >
                                        {
                                            loading.add ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Add</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Name</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categories.data.map((c, index) => {
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-lright_solid1_f1">#{c.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                    <input
                                                        type={'text'}
                                                        className='input_up'
                                                        defaultValue={c.name}
                                                        onChange={(e) => toggleHandler({ e, index })}
                                                    />
                                                </td>
                                                <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                    <button
                                                        disabled={loading.update}
                                                        onClick={() => updateCateg(c)}
                                                        className='select-none delete buttom_up '
                                                    >
                                                        {
                                                            loading.update ?
                                                                <div className="spin update"></div>
                                                                :
                                                                <div>Update</div>
                                                        }
                                                    </button>
                                                    <button
                                                        disabled={loading.delete}
                                                        onClick={() => _delete(c)}
                                                        className='select-none delete buttom_delete mg-left_10'
                                                    >
                                                        {
                                                            loading.delete ?
                                                                <div className="spin update"></div>
                                                                :
                                                                <div>Delete</div>
                                                        }
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            <Paginator
                data={categories}
                action={(c) => getCategories(c)}
            />
        </div>
    )
}

export default GetCategories