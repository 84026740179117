export const conection = [
    {
        value: false, reason: '-select provider-'
    },
    {
        value: true, reason: 'dhru'
    },
    {
        value: true, reason: 'eneyvol'
    },
]