import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import ModalDelete from "../../../../components/ModalDelete";
import JoditEditor from "jodit-react";
import { Update_servers, Delete_server } from "../../../../store/storeReducers/ServicesReducer";
import { custom, active } from "../../../../constants/status";
import { get_groups } from "../../../../functions/GroupsServicefunction";
import { get_service, update_service, delete_service } from "../../../../functions/Servicefunction";
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";

const UpdateServer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const param = useOutletContext();
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState({
        update: false,
        delete: false,
        modal: false
    });
    const [group, setGroup] = useState("");
    const [service, setService] = useState({});

    const [array, setArray] = useState({
        "id": '',
        "name": ''
    })

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getService(param.id);
        }
    }, [param.count]);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        });
        setService({
            ...service,
            [e.target.name]: e.target.value
        });
        if (e.target.name == 'qntoption') {
            setService({
                ...service,
                qntoption: e.target.value && e.target.value
            });
        }
    };

    const getGroups = async () => {
        try {
            const res = await get_groups({ action: 'groups', type: 'SERVER' });
            setGroup(res.data.groups);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const getService = async (id) => {
        try {
            const res = await get_service({ id, action: 'server' });
            setService(res.data.service);
        } catch (error) {
            alert(error.response.data);
        }
        await getGroups();
    }

    const updateService = async () => {
        setLoad({ ...loading, update: true });
        try {
            service.action = 'data-info';
            const res = await update_service(service);
            dispatch(Update_servers(res.data.service))
            alert(res.data.success);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const deleteService = async () => {
        setLoad({ ...loading, modal: false, delete: true });
        try {
            const res = await delete_service(service.id);
            dispatch(Delete_server(service));
            alert(res.data.alert)
            navigate('/get-servers');
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, delete: false });
    }

    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({ ...loading, modal: !loading.modal })}
                action={() => deleteService()}
            />
            {
                !line &&
                <div>
                    <table className="table-add" >
                        <tbody>
                            <tr>
                                <td >Group: </td>
                                <td >
                                    <select
                                        className='outline-none pd-0-10p'
                                        value={service.groupimei_id}
                                        onChange={(e) => handleChange(e)}
                                        name="groupimei_id"
                                    >
                                        {
                                            [array, ...group].map((g, k) => {
                                                return (
                                                    <option key={k} value={g.id}>
                                                        {g.name ? g.name : '-Select one group-'}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td >Name:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.name}
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >Active:</td>
                                <td >
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='isActive'
                                        onChange={(e) => handleChange(e)}
                                        value={service.isActive}
                                    >
                                        {
                                            active.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td >Default price:</td>
                                <td className='fieldlabel'>
                                    <span
                                        className='outline-none pd-0-10p'>
                                        {(parseFloat(service.cost).toFixed(2) || (0).toFixed(2))}
                                    </span>
                                </td>
                                <td className='fieldlabel'>
                                    <button
                                        onClick={() => navigate(`/get-servers/custom-prices?reference_id=${param.id}`)}
                                        className='select-none delete buttom_up '
                                    >
                                        <span>service pricing</span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td >Delivery time:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        defaultValue={service.time}
                                        name='time'
                                        type="text"
                                        placeholder="Time:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >Position:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        defaultValue={service.position}
                                        name='position'
                                        type="number"
                                        placeholder="Position:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >Coin:</td>
                                <td >
                                    <select
                                        className='outline-none pd-0-10p'
                                        value={service.coin}
                                        name="coin"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option>
                                            -Select one option-
                                        </option>
                                        <option value={"MX"}>
                                            MX
                                        </option>
                                        <option value={"USD"}>
                                            USD
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Active QNT:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='qnt'
                                        onChange={(e) => handleChange(e)}
                                        value={service.qnt}
                                    >
                                        {
                                            custom.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td >QNT min:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.minqnt}
                                        name="minqnt"
                                        type='number'
                                        placeholder="min:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.minqnt}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td >QNT max:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.maxqnt}
                                        name="maxqnt"
                                        type='number'
                                        placeholder="max:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.maxqnt}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td >QNT option:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.qntoption}
                                        name="qntoption"
                                        type='text'
                                        placeholder="Qnt option:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.qntoption}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Example user:
                                </td>
                                <td>
                                    <select
                                        className='outline-none pd-0-10p'
                                    >
                                        {
                                            service.qntoption &&
                                            service.qntoption.split(',').map((q, K) => {
                                                return (
                                                    <option key={K}>
                                                        {q}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <button
                                        disabled={loading.update}
                                        onClick={() => updateService()}
                                    >
                                        {
                                            loading.update ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Update</div>
                                        }
                                    </button>
                                </td>
                                <td className="active" >
                                    <button
                                        disabled={loading.delete}
                                        className="delete"
                                        onClick={() => setLoad({ ...loading, modal: true })}
                                    >
                                        {
                                            loading.delete ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Delete</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ width: '60%' }}>
                        <JoditEditor
                            value={service.description}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setService((state) => ({ ...state, description: newContent }))}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default UpdateServer;