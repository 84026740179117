import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { NavigateIndex } from '../../components/NavigateIndex';

const IndexOrdersServer = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [count, setCount] = useState(0);
    const [className, setClass] = useState(
        location && location.pathname
    );
    const belong = 'orderServer';

    useEffect(() => {
        if (location) {
            setClass(location.pathname);
        }
    }, [location])

    const handleClass = (c) => {
        setCount(count + 1);
        navigate(c.path);
        setClass(c.className);
    }

    return (
        <div>
            <NavigateIndex
                handleClass={(c) => handleClass(c)}
                className={className}
                belong={belong}
            />
            <Outlet context={{ count: count }} />
        </div>
    )
}

export default IndexOrdersServer;