import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_customers = (u) => {
    var url = u.page ? u.page : `${api}/customer`;
    return axiosConfig.get(url, {
        params: {
            action: u.action,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}

export const get_customer = (u) => {
    var url = u.page ? u.page : `${api}/customer/${u.id}`;
    return axiosConfig.get(`${url}`, {
    }).then(response => response);
}

export const create_customer = (customer) => {
    var url = `${api}/customer`;
    return axiosConfig.post(url, customer, {
    }).then(response => response);
}

export const delete_customer = (id) => {
    var url = `${api}/customer`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}

export const update_customer = (customer) => {
    var url = `${api}/customer`;
    return axiosConfig.put(`${url}/${customer.id}`, customer, {
    }).then(response => response);
}