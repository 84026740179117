import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    apis: {
        apis: { data: [] }
    }
}

export const apisSlice = createSlice({
    name: 'apis',
    initialState,
    reducers: {
        SetApis: (state, action) => {
            state.apis.apis = action.payload
        },
        Update_apis: (state, action) => {
            let b = state.apis.apis.data.map(api => {
                if (api.id !== action.payload.id) {
                    return api;
                } else {
                    return action.payload;
                }
            });
            state.apis.apis = {
                ...state.apis.apis,
                data: b
            }
        },
        Delete_apis: (state, action) => {
            let a = state.apis.apis.data.filter(api => {
                return api.id !== action.payload.id;
            })
            state.apis.apis = {
                ...state.apis.apis,
                data: a
            }
        }
    }

})

export const { SetApis, Update_apis, Delete_apis } = apisSlice.actions


export default apisSlice.reducer