import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tickets: {
        tickets: { data: [] }
    }
}

export const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        SetTickets: (state, action) => {
            state.tickets.tickets = action.payload
        },
        Update_tickets: (state, action) => {
            let b = state.tickets.tickets.data.map(ticket => {
                if (ticket.id !== action.payload.id) {
                    return ticket;
                } else {
                    return action.payload;
                }
            });
            state.tickets.tickets = {
                ...state.tickets.tickets,
                data: b
            }
        },
        Delete_tickets: (state, action) => {
            let a = state.tickets.tickets.data.filter(ticket => {
                return ticket.id !== action.payload.id;
            })
            state.tickets.tickets = {
                ...state.tickets.tickets,
                data: a
            }
        },
    }
})

export const { SetTickets, Update_tickets, Delete_tickets } = ticketsSlice.actions

export default ticketsSlice.reducer