import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bills: {
        bills: { data: [] }
    }
}

export const billsSlice = createSlice({
    name: 'bills',
    initialState,
    reducers: {
        SetBills: (state, action) => {
            state.bills.bills = action.payload
        }
    }
})

export const { SetBills } = billsSlice.actions

export default billsSlice.reducer