import React, { useEffect } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api/ConnectApi";
import axios from "axios";
import moment from 'moment'
import { SetBanks } from "../../../store/storeReducers/BanksReducer";
import { ImagenStatus } from "../../../components/ImagenStatus";
import Search from "../../../components/Search";
import Paginator from "../../../components/Paginator";
import { get_banks } from "../../../functions/Bankfunction";
import { update_Line } from "../../../store/storeReducers/LoadingReducer";

const GetBanks = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const banks = useSelector((state) => state.banks.banks.banks);

    useEffect(() => {
        if (banks && !banks.data[0]) {
            dispatch(update_Line(true));
            getBanks();
        }
    }, []);

    const getBanks = async (value) => {
        try {
            const bank = {
                action: 'banks',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_banks(bank);
            dispatch(SetBanks(res.data.banks));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getBanks(p)}
                        value={"Search by name..."}
                    />
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Name</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Num Account</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Holder Name</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Status</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Register day</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    banks.data.map((bank, index) => {
                                        const status = ImagenStatus(parseFloat(bank.isActive));
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">#{bank.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{bank.name}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{bank.num_account}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 white_space">{bank.holder_name}</td>
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{status}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1"> {moment(bank.created_at).format('Y-MM-DD')}</td>
                                                <td onClick={() => navigate(`/get-banks/bank-info?reference_id=${bank.id}`)} className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 cursor-pointer">< AiOutlineEdit /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={banks}
                action={(p) => getBanks(p)}
            />
        </div>
    );
}

export default GetBanks