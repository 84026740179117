import React, { useEffect, useState } from "react";
import Paginator from "../../../components/Paginator";
import ModalDelete from '../../../components/ModalDelete'
import Search from '../../../components/Search'
import { get_images, delete_imagen } from "../../../functions/Imagesfunction";
import { useSelector, useDispatch } from "react-redux";
import { update_Line } from "../../../store/storeReducers/LoadingReducer";

const GetImages = () => {
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [imgs, setImgs] = useState([]);
    const [loading, setLoad] = useState({
        modal: false
    });
    const [img, setImg] = useState();

    useEffect(() => {
        dispatch(update_Line(true));
        getImages()
    }, [])

    const getImages = async (value) => {
        try {
            const imagen = {
                action: 'images',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_images(imagen);
            setImgs(res.data.images)
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const deletImg = async () => {
        setLoad({ ...loading, modal: false });
        try {
            const res = await delete_imagen(img.id);
            alert(res.data.alert);
            setImgs(res.data.imagens)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
    }

    const handleChange = (i) => {
        setLoad({ ...loading, modal: true });
        setImg(i);
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(i) => getImages(i)}
                        value={"Search by name..."}
                    />
                    <ModalDelete
                        isVisible={loading.modal}
                        close={() => setLoad({ ...loading, modal: !loading.modal })}
                        action={() => deletImg()}
                    />
                    <div className="dp-grid images mg-bottom_50">
                        {
                            imgs && imgs.data &&
                            imgs.data.map((img, k) => {
                                return (
                                    <div key={k}>
                                        <div className="default_dg_td tx-center bd-left_solid1_f1 bd-right_solid1_f1 mg-bottom_7">
                                            <div>
                                                <span className="ft-size_12 ft-weight_bold">
                                                    {img.name}
                                                </span>
                                            </div>
                                            <img height={200} width={200} src={img.uri} />
                                            <div>
                                                <span className="ft-size_12 font-weight_bold">
                                                    Category: {img.category_image && img.category_image.name}
                                                </span>
                                            </div>
                                            <div className="mg-top_10">
                                                <button
                                                    disabled={loading.delete}
                                                    className="select-none cursor-pointer bg-313c42 color-white outline-none ft-size_14 bd-radius_2 tx-center bd-none mg-right_10"
                                                    onClick={() => handleChange(img)}>
                                                    Delete
                                                </button>

                                                <button className="select-none cursor-pointer bg-313c42 color-white outline-none ft-size_14 bd-radius_2 tx-center bd-none mg-left_10" onClick={() => window.open(img.uri)}>Pre-view</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
            <div>
                < Paginator
                    data={imgs}
                    action={(i) => getImages(i)}
                />
            </div>
        </div>
    )
}

export default GetImages