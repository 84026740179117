
import axios from 'axios';

const instance = axios.create({
    // .. where we make our configurations
    // baseURL: 'https://myapp-gsma.ngrok.io/api/eneyvol'
});
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    var dataBack = JSON.parse(localStorage.getItem("save_auth"));
    const token = dataBack ? dataBack.access_token : '';
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers['Content-Type'] = 'application/json';
    return config;
});

export default instance;