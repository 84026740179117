import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Update_services, Delete_services } from "../../../../../store/storeReducers/ServicesReducer";
import { custom, active } from "../../../../../constants/status";
import { get_service } from "../../../../../functions/Servicefunction";
import { update_Line } from "../../../../../store/storeReducers/LoadingReducer";
import { update_serviceprice } from "../../../../../functions/Priceservicefunction";


const PricingService = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const param = useOutletContext();
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState({
        update: false
    });
    const [servicesprice, setServicesprice] = useState([]);

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getService(param.id);
        }
    }, [param.count]);

    const getService = async (id) => {
        try {
            const res = await get_service({ id, action: 'prices' });
            setServicesprice(res.data.servicesprice);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateServiceprice = async (service) => {
        setLoad({ ...loading, update: true });
        const serviceprice = {
            id: service.id,
            active: service.active,
            price: service.price,
        }
        try {
            const res = await update_serviceprice(serviceprice);
            alert(res.data.alert);
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const toggleHandler = (item) => {
        let newArray = [...servicesprice];
        if (item.action == 'value') {
            newArray[item.index].price = item.e.target.value ? item.e.target.value : 0
        } else if (item.action == 'check') {
            newArray[item.index].active = item.e.target.checked
        }
        setServicesprice(newArray);
    };

    return (
        <div>
            {
                !line &&
                <div>
                    <table className=" default_dg_table" >
                        <thead>
                            <tr>
                                <th className="default_dg_td tx-center default_dg_a_header">Active</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Custom group name</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Base price</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Change price</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Apply</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                servicesprice.map((price, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1 "
                                            >
                                                <input
                                                    className="cursor-pointer"
                                                    type="checkbox"
                                                    checked={price.active}
                                                    onChange={e => toggleHandler({ e, price, index, action: 'check' })}
                                                />
                                            </td>
                                            <td className="default_dg_td tx-left dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                {
                                                    price.customers.groupname
                                                }
                                            </td>
                                            <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                {
                                                    (parseFloat(price.price).toFixed(2) || (0).toFixed(2))
                                                }
                                            </td>
                                            <td className="default_dg_td tx-left dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                <input
                                                    type={'text'}
                                                    className='input_up'
                                                    onChange={e => toggleHandler({ e, price, index, action: 'value' })}
                                                />
                                            </td>
                                            <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                <button
                                                    disabled={loading.update}
                                                    className="select-none buttom_up"
                                                    onClick={() => updateServiceprice(price)}
                                                >
                                                    {
                                                        loading.update ?
                                                            <div className="spin update"></div>
                                                            :
                                                            <span>Update</span>
                                                    }
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
}

export default PricingService;