import React, { useEffect, useState } from 'react';
import { update_Line } from '../../../../store/storeReducers/LoadingReducer';
import { get_groups } from "../../../../functions/GroupsServicefunction";
import { useSelector, useDispatch } from "react-redux";
import { get_serviceprice, update_serviceprice } from '../../../../functions/Priceservicefunction';
import { useNavigate, useOutletContext } from "react-router-dom";


const ServerPricing = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const [group, setGroup] = useState([]);
    const [error, setErr] = useState({ err: [] });
    const line = useSelector((state) => state.loading.loading.line);
    const [serviceprice, setServiceprice] = useState([]);
    const [loading, setLoad] = useState({
        update: false
    });
    const [newprice, setPrice] = useState({});

    useEffect(() => {
        dispatch(update_Line(true));
        getGroups();
    }, [param.count]);

    const getGroups = async () => {
        try {
            const res = await get_groups({ action: 'groups', type: 'SERVER' });
            setGroup(res.data.groups);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const [array, setArray] = useState({
        "id": '',
        "name": ''
    })

    const getServiceprice = async (id) => {
        try {
            const res = await get_serviceprice({ idcat: id, customer: param.id });
            if (res.data) {
                setServiceprice(res.data.serviceprice);
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));

    }

    const updateServiceprice = async (service) => {
        setLoad({ ...loading, update: true });
        const serviceprice = {
            id: service.serviceprice.id,
            active: service.serviceprice.active,
            price: service.serviceprice.price,
        }
        try {
            const res = await update_serviceprice(serviceprice);
            alert(res.data.alert);
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const toggleHandler = (item) => {
        let newArray = [...serviceprice];
        if (item.action == 'value') {
            newArray[item.index].serviceprice.price = item.e.target.value ? item.e.target.value : 0
        } else if (item.action == 'check') {
            newArray[item.index].serviceprice.active = item.e.target.checked
        }
        setServiceprice(newArray);
    };

    return (
        <div >
            {
                !line &&
                <div>
                    <table className="table-add" >
                        <tbody>
                            <tr>
                                <td className='fieldlabel'>Group: </td>
                                {
                                    group == "" || group &&
                                    <td className='fieldlabel'>
                                        <select
                                            className='outline-none pd-0-10p'
                                            onChange={(e) => getServiceprice(e.target.value)}
                                            name="group_id"
                                        >
                                            {
                                                [array, ...group].map((g, k) => {
                                                    return (
                                                        <option key={k} value={g.id}>
                                                            {g.name ? g.name : '-Select one group-'}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <div >
                                            <span className="color-red ft-size_11 ft-family-monospace">
                                                {error.group_id}
                                            </span>
                                        </div>
                                    </td>
                                }
                            </tr>
                        </tbody>
                    </table>

                    <table className="default_dg_table">
                        <thead>
                            <tr>
                                <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Service name</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Active</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Default</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Price</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Change price</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Update</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                serviceprice.map((price, index) => {
                                    return (
                                        <tr key={index} className="dg_tr">
                                            {
                                                price && price.serviceprice &&
                                                <>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">#{price.serviceprice.id}</td>
                                                    <td className="default_dg_td tx-left dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">{price.name}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                        <input
                                                            type="checkbox"
                                                            checked={price.serviceprice.active}
                                                            onChange={e => toggleHandler({ e, price, index, action: 'check' })}
                                                        />
                                                    </td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1 opacity-07">{(parseFloat(price.cost).toFixed(2) || (0).toFixed(2))}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">{(parseFloat(price.serviceprice.price).toFixed(2) || (0).toFixed(2))}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                        <input
                                                            type={'text'}
                                                            className='input_up'
                                                            onChange={e => toggleHandler({ e, price, index, action: 'value' })}
                                                        />
                                                    </td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                        <button
                                                            disabled={loading.update}
                                                            onClick={() => updateServiceprice(price)}
                                                            className='select-none delete buttom_up'
                                                        >
                                                            {
                                                                loading.update ?
                                                                    <div className="spin update"></div>
                                                                    :
                                                                    <div>Update</div>
                                                            }
                                                        </button>
                                                    </td>
                                                </>
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default ServerPricing;