import React, { useEffect } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api/ConnectApi";
import axios from "axios";
import moment from 'moment'
import { SetApis } from "../../../store/storeReducers/ApisReducer";
import Search from "../../../components/Search";
import Paginator from "../../../components/Paginator";
import { get_apis } from "../../../functions/Apifunction";
import { update_Line } from "../../../store/storeReducers/LoadingReducer";

const GetApis = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const api_services = useSelector((state) => state.apis.apis.apis);

    useEffect(() => {
        if (api_services && !api_services.data[0]) {
            dispatch(update_Line(true));
            getApis();
        }
    }, []);

    const getApis = async (value) => {
        try {
            const api = {
                action: 'apis',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_apis(api);
            dispatch(SetApis(res.data.api_services));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getApis(p)}
                        value={"Search by name..."}
                    />
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Action api</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Name</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">URL</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Username</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Api key</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Registration Day</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    api_services.data.map((aps, index) => {
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">#{aps.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">action_{aps.action_api}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 white_space">{aps.name}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 white_space">{aps.url}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{aps.username}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{aps.api_key}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1"> {moment(aps.created_at).format('Y-MM-DD')}</td>
                                                <td onClick={() => navigate(`/get-apis/api-info?profile_id=${aps.id}`)} className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 cursor-pointer">< AiOutlineEdit /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            <Paginator
                data={api_services}
                action={(p) => getApis(p)}
            />
        </div>
    );
}

export default GetApis