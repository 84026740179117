export const custom = [
    {
        isActive: 0, name: 'Disabled'
    },
    {
        isActive: 1, name: 'Actived'
    }
]

export const active = [
    {
        isActive: 1, name: 'Active'
    },
    {
        isActive: 0, name: 'Inactive'
    },
]


export const status = [
    {
        "name": "waiting",
        status: 0
    },
    {
        "name": "proccess",
        status: 1
    },
    {
        "name": "reject",
        status: 3
    },
    {
        name: "success",
        status: 4
    },
]