import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { NavigateIndex } from '../../components/NavigateIndex';
import { get_settings, update_settings } from "../../functions/Settingsfunction";
import { useSelector, useDispatch } from "react-redux";
import { update_Line } from '../../store/storeReducers/LoadingReducer';

const GeneralSettings = () => {
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [loading, setLoad] = useState({
        update: false
    });
    const [general, setGeneral] = useState({
        logonav: {
            id: '',
            value: ''
        },
        homeimg: {
            id: '',
            value: ''
        }
    })

    useEffect(() => {
        dispatch(update_Line(true));
        get_general();
    }, []);

    const get_general = async () => {
        try {
            const res = await get_settings();
            if (res.data.data) {
                setGeneral(JSON.parse(res.data.data.general));
            }
        } catch (error) {
        }
        dispatch(update_Line(false));
    }

    const update_general = async (value) => {
        setLoad({ ...loading, update: true });
        try {
            const res = await update_settings(value);
            alert(res.data.alert);
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const handleChange = (e) => {
        setGeneral({
            ...general,
            [e.target.name]: { value: e.target.value, id: general[e.target.name].id }
        })
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <table className="table-add" >
                        <tbody>
                            <tr>
                                <td className="fieldlabel">Logo nav:</td>
                                <td className="fieldlabel">
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={general.logonav.value}
                                        name="logonav"
                                        type="text"
                                        placeholder="Logo:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                                <td className="fieldlabel">
                                    <button
                                        disabled={loading.update}
                                        className="delete"
                                        onClick={() => update_general(general.logonav)}
                                    >
                                        {
                                            loading.update ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Change</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="fieldlabel">Img screen:</td>
                                <td className="fieldlabel">
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={general.homeimg.value}
                                        name="homeimg"
                                        type="text"
                                        placeholder="Imagen:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                                <td className="fieldlabel">
                                    <button
                                        disabled={loading.update}
                                        className="delete"
                                        onClick={() => update_general(general.homeimg)}
                                    >
                                        {
                                            loading.update ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Change</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default GeneralSettings;