import React, { useEffect, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Shortcuts } from '../Shortcuts';
import { useNavigate } from 'react-router-dom';
import { sign_out } from '../functions/Authfunction';
import { SetOut } from '../store/storeReducers/AuthReducer';
import { useDispatch } from 'react-redux';

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [clas, setClas] = useState('');
    const style = document.documentElement.style;

    useEffect(() => {
        handleStyle();
    }, [])

    const handleStyle = () => {
        if (style.getPropertyValue('--Sidebar') === '260px') {
            style.setProperty('--Sidebar', '60px');
            style.setProperty('--Con-tent', '70px');
            style.setProperty('--Subs', '60px');
            style.setProperty('--Paginate', '90px');
            setClas('hide');
        } else {
            setClas('');
            style.setProperty('--Sidebar', '260px');
            style.setProperty('--Con-tent', '260px');
            style.setProperty('--Subs', '259.5px');
            style.setProperty('--Paginate', '260px');
        }
    }

    const submitLogout = async () => {
        try {
            const res = await sign_out();
            localStorage.removeItem('save_auth');
            dispatch(SetOut(res.data))
            navigate('/')
        } catch (error) {
        }
    }

    return (
        <div className='side-bar'>
            <div>
                <ul className='hover pd-10'>
                    {
                        Shortcuts.map((s, k) => {
                            return (
                                <li className={`wd-100p sub-show ${clas} select-none pd-10 dp-flex itm-center short-one ft-weight_700 ft-size_14 color-545b64 bd-bt_solid`} key={k}
                                >
                                    <a className='dp-flex'>
                                        <i className='pd-right_10 ft-size_18 dp-flex itm-center'>{s.icon}</i>
                                        <span >{s.name}</span>
                                    </a>
                                    <div className='subs'>
                                        {
                                            s.sub.map((sub, key) => {
                                                return (
                                                    <a key={key} onClick={() => { sub.subname === 'Sign out' ? submitLogout() : navigate(sub.path, { state: sub }) }} className='select-subs'>
                                                        {sub.subname}
                                                    </a>
                                                )
                                            })
                                        }
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='hambg'>
                <a onClick={() => handleStyle()}>
                    <i><GiHamburgerMenu /> </i>
                </a>
            </div>
        </div>
    )
}

export default Sidebar