import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_groups = (u) => {
    var url = u.page ? u.page : `${api}/groupsimei`;
    return axiosConfig.get(url, {
        params: {
            action: u.action,
            search: u.search,
            per_page: u.per_page,
            type: u.type
        },
    }).then(response => response);
}

// export const get_group = (id) => {
//     var url = `${api}/groupsimei`;
//     var action = 'group';
//     return axiosConfig.get(`${url}/${id}`, {
//         params: {
//             action: action
//         },
//     }).then(response => response);
// }

export const create_group = (v) => {
    var url = `${api}/groupsimei`;
    return axiosConfig.post(`${url}`, v, {
    }).then(response => response);
}

export const update_group = (group) => {
    var url = `${api}/groupsimei`;
    return axiosConfig.put(`${url}/${group.id}`, group, {
    }).then(response => response);
}

export const delete_group = (id) => {
    var url = `${api}/groupsimei`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}