import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    groups: {
        groups: {
            data: []
        },
        groupserver: {
            data: []
        },
    }
}

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        SetGroups: (state, action) => {
            state.groups.groups = action.payload
        },
        SetGroupsServer: (state, action) => {
            state.groups.groupserver = action.payload
        },
        Update_groups: (state, action) => {
            let b = state.groups.groups.data.map(group => {
                if (group.id !== action.payload.id) {
                    return group;
                } else {
                    return action.payload;
                }
            });
            state.groups.groups = {
                ...state.groups.groups,
                data: b
            }
        },
        Update_groupsServer: (state, action) => {
            let b = state.groups.groupserver.data.map(group => {
                if (group.id !== action.payload.id) {
                    return group;
                } else {
                    return action.payload;
                }
            });
            state.groups.groupserver = {
                ...state.groups.groupserver,
                data: b
            }
        },
        Delete_groups: (state, action) => {
            let a = state.groups.groups.data.filter(group => {
                return group.id !== action.payload.id;
            })
            state.groups.groups = {
                ...state.groups.groups,
                data: a
            }
        },
        DeleteGroupsServer: (state, action) => {
            let a = state.groups.groupserver.data.filter(group => {
                return group.id !== action.payload.id;
            })
            state.groups.groupserver = {
                ...state.groups.groupserver,
                data: a
            }
        },
    }
})

export const { SetGroups, Update_groups, Delete_groups, SetGroupsServer, Update_groupsServer, DeleteGroupsServer } = groupsSlice.actions

export default groupsSlice.reducer