import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { add_custom, delete_custom } from '../../../../../functions/Customfunction';
import { get_service } from '../../../../../functions/Servicefunction';
import { update_Line } from '../../../../../store/storeReducers/LoadingReducer';

const CustomServer = () => {

    const line = useSelector((state) => state.loading.loading.line);
    const dispatch = useDispatch();
    const [service, setService] = useState([]);
    const [custom, setCustom] = useState({});
    const [error, setErr] = useState({ err: [] });
    const param = useOutletContext();

    useEffect(() => {
        dispatch(update_Line(true));
        if (param) {
            getService(param.id);
        }
    }, [param.count])

    const getService = async (id) => {
        try {
            const res = await get_service({ id, action: 'custom' });
            setService(res.data.custom);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const addCustom = async () => {
        const value = {
            ...custom,
            serviceimei_id: param.id,
            type: 'serviceimei'
        }
        try {
            const res = await add_custom(value);
            setService(res.data.customs);
            alert(res.data.alert);
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.err) {
                    setErr(error.response.data.err);
                }
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
    }

    const deleteCustom = async (id) => {
        try {
            const res = await delete_custom(id);
            if (res.data) {
                let a = service.filter(s => {
                    return s.id !== id;
                })
                setService(a);
            }
            alert(res.data.alert);
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
    }

    const handleChange = (e) => {
        if (e.target.name === 'required') {
            setCustom({
                ...custom,
                [e.target.name]: e.target.checked
            })
        } else {
            setErr({
                ...error,
                [e.target.name]: ''
            });
            setCustom({
                ...custom,
                [e.target.name]: e.target.value
            })
        }

    }

    const fieldType = [
        { name: '-Select type-', value: '' },
        { name: 'Text', value: 'Text' },
        { name: 'Dropdown', value: 'Dropdown' },
        { name: 'Radio', value: 'Radio' },
        { name: 'Textarea', value: 'Textarea' },
        { name: 'Tickbox', value: 'Tickbox' }
    ]

    return (
        <div>
            {
                !line &&
                <div>
                    <table className='default_dg_table table-add custom'>
                        <thead>
                            <tr>
                                <th className='default_dg_td tx-center default_dg_a_header'>Field Type</th>
                                <th className='default_dg_td tx-center default_dg_a_header'>Name</th>
                                <th className='default_dg_td tx-center default_dg_a_header'>Description</th>
                                <th className='default_dg_td tx-center default_dg_a_header'>Field Options</th>
                                <th className='default_dg_td tx-center default_dg_a_header'>Validation</th>
                                <th className='default_dg_td tx-center default_dg_a_header'>Required</th>
                                <th className='default_dg_td tx-center default_dg_a_header'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='dg_tr'>
                                <td className="default_dg_td bd-left_solid1_f1 bd-right_solid1_f1" >
                                    <span>
                                        <select
                                            name='fieldtype'
                                            onChange={(e) => handleChange(e)}
                                            className='pd-0-10p outline-none'
                                        >
                                            {
                                                fieldType.map((t, k) => {
                                                    return (
                                                        <option key={k} value={t.value}>
                                                            {t.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </span>
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.fieldtype}
                                        </span>
                                    </div>
                                </td>
                                <td className="default_dg_td bd-left_solid1_f1 bd-right_solid1_f1" >
                                    <input
                                        onChange={(e) => handleChange(e)}
                                        defaultValue={''}
                                        name='fieldname'
                                        className='outline-none pd-0-10p' />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.fieldname}
                                        </span>
                                    </div>
                                </td>
                                <td className="default_dg_td bd-left_solid1_f1 bd-right_solid1_f1">
                                    <input
                                        defaultValue={''}
                                        onChange={(e) => handleChange(e)}
                                        name='description'
                                        className='outline-none pd-0-10p' />
                                </td>
                                <td className="default_dg_td bd-left_solid1_f1 bd-right_solid1_f1">
                                    <input
                                        defaultValue={''}
                                        onChange={(e) => handleChange(e)}
                                        name='fieldoptions'
                                        className='outline-none pd-0-10p' />
                                </td>
                                <td className="default_dg_td bd-left_solid1_f1 bd-right_solid1_f1">
                                    <input
                                        defaultValue={''}
                                        onChange={(e) => handleChange(e)}
                                        name='regexpr'
                                        className='outline-none pd-0-10p' />
                                </td>
                                <td className="default_dg_td tx-center bd-left_solid1_f1 bd-right_solid1_f1">
                                    <div>
                                        <label>
                                            <input
                                                defaultValue={''}
                                                onChange={(e) => handleChange(e)}
                                                name='required'
                                                className='outline-none '
                                                type={'checkbox'} />
                                        </label>
                                    </div>
                                </td>
                                <td className="tx-center default_dg_td bd-left_solid1_f1 bd-right_solid1_f1">
                                    <span>
                                        <button
                                            onClick={() => addCustom()}
                                        >
                                            +Add
                                        </button>
                                    </span>
                                </td>
                            </tr>
                            {
                                service.map((s, k) => {
                                    return (
                                        <tr className='dg_tr' key={k}>
                                            <td className="default_dg_td tx-center bd-left_solid1_f1 bd-right_solid1_f1" >
                                                <span>
                                                    <select
                                                        className='outline-none pd-0-10p'
                                                        defaultValue={s.fieldtype}
                                                    >
                                                        {
                                                            fieldType.map((t, k) => {
                                                                return (
                                                                    <option key={k}>
                                                                        {t.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </span>
                                            </td>
                                            <td className="default_dg_td bd-left_solid1_f1 bd-right_solid1_f1">
                                                <input
                                                    className='outline-none pd-0-10p'
                                                    defaultValue={s.fieldname}
                                                />
                                            </td>
                                            <td className="default_dg_td bd-left_solid1_f1 bd-right_solid1_f1">
                                                <input
                                                    className='outline-none pd-0-10p'
                                                    defaultValue={s.description}
                                                />
                                            </td>
                                            <td className="default_dg_td bd-left_solid1_f1 bd-right_solid1_f1">
                                                <input
                                                    className='outline-none pd-0-10p'
                                                    defaultValue={s.fieldoptions}
                                                />
                                            </td>
                                            <td className="default_dg_td bd-left_solid1_f1 bd-right_solid1_f1">
                                                <input
                                                    className='outline-none pd-0-10p'
                                                    defaultValue={s.regexpr}
                                                />
                                            </td>
                                            <td className="default_dg_td tx-center bd-left_solid1_f1 bd-right_solid1_f1">
                                                <div >
                                                    <label>
                                                        <input
                                                            className='outline-none'
                                                            defaultChecked={s.required}
                                                            type={'checkbox'}
                                                        />
                                                    </label>
                                                </div>
                                            </td>
                                            <td className="tx-center default_dg_td bd-left_solid1_f1 bd-right_solid1_f1">
                                                <span>
                                                    <button
                                                        onClick={() => deleteCustom(s.id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div >
    )
}

export default CustomServer;