import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../../../api/ConnectApi";
import { useSelector, useDispatch } from "react-redux";
import Moment from 'moment';
import { useOutletContext } from "react-router-dom";
import Paginator from "../../../components/Paginator";
import { get_orders, await_order } from "../../../functions/Ordersfunction";
import { Status_edit } from "../../../store/storeReducers/OrdersReducer";
import { Balance_users } from "../../../store/storeReducers/UsersReducer";
import { update_Line } from "../../../store/storeReducers/LoadingReducer";

const AnsweredOrders = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const [orders, setOrders] = useState({ data: [] });
    const line = useSelector((state) => state.loading.loading.line);
    const [response, setResponse] = useState({});
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState({
        update: false
    });

    useEffect(() => {
        dispatch(update_Line(true));
        getOrders();
    }, [param.count]);// eslint-disable-line react-hooks/exhaustive-deps

    const toggleHandler = (event) => {
        const order = {}
        order.id = event.u.id;
        order.status = event.status;
        order.response = event.e.target.value;
        setResponse((state) => ({
            ...state,
            [order.id]: state[order.id] ?
                order.response ?
                    state[order.id].status ?
                        order.status === 3 || order.status === 4 ?
                            state[order.id].status === order.status ?
                                {
                                    id: order.id,
                                    status: null,
                                    response: order.response
                                }
                                :
                                {
                                    id: order.id,
                                    status: order.status,
                                    response: order.response
                                }
                            :
                            {
                                id: order.id,
                                status: state[order.id].status,
                                response: order.response
                            }
                        :
                        {
                            id: order.id,
                            status: order.status,
                            response: order.response
                        }
                    :
                    null
                :
                order.response ?
                    {
                        id: order.id,
                        status: order.status,
                        response: order.response
                    }
                    :
                    {
                        id: order.id,
                        status: order.status,
                        response: order.status === 3 ? 'NOT FOUND' : 'SUCCESSFUL'
                    }
        }));
    };

    const getOrders = async (value) => {
        try {
            const order = {
                action: 'proccess',
                type: 'IMEI',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_orders(order);
            setOrders(res.data.orders);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const sendStatus = async () => {
        setLoad({ ...loading, update: true });
        try {
            const o = { action: 'in-proccess', orders: response }
            const res = await await_order(o);
            if (res.data.orders) {
                res.data.orders.map((ord) => {
                    dispatch(Status_edit(ord));
                })
            }
            if (res.data.users) {
                res.data.users.map((us) => {
                    dispatch(Balance_users(us))
                })
            }
            await getOrders();
        } catch (err) {
            if (err.response.data && err.response.data.err) {
                setErr(err.response.data.err);
            }
        }
        setResponse({});
        setLoad({ ...loading, update: false });
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <div >
                        <span className="color-red ft-size_11 ft-family-monospace">
                            {error.orders}
                        </span>
                    </div>
                    <table className="default_dg_table" >
                        <thead>
                            <tr>
                                <th className="default_dg_td dg_center default_dg_a_header">Id</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Imei</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Service</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Day</th>
                                <th className="default_dg_td tx-left default_dg_a_header">User</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Api</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Response</th>
                                <th className="default_dg_td tx-left default_dg_a_header">Price</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Reject</th>
                                <th className="default_dg_td tx-center default_dg_a_header">Success</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders.data.map((u, i) => {
                                    return (
                                        <tr key={i} className="dg_tr">
                                            <td className="default_dg_td tx-center dg_nowrap " >#{u.id}</td>
                                            <td className="default_dg_td tx-left dg_nowrap td_border  bd_left_solid1_f1 bd_lright_solid1_f1">{u.imei}</td>
                                            <td className="default_dg_td tx-left dg_nowrap td_border  bd_left_solid1_f1 bd_lright_solid1_f1 white_space">{u.serviceimei.name}</td>
                                            <td className="default_dg_td tx-left dg_nowrap td_border  bd_left_solid1_f1 bd_lright_solid1_f1">{Moment(u.created_at).format('D-MMM-yy')}</td>
                                            <td className="default_dg_td tx-left dg_nowrap td_border  bd_left_solid1_f1 bd_lright_solid1_f1">{u.user.email}</td>
                                            <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">  {
                                                u.serviceimei.isApi && u.serviceimei.api_service_id ?
                                                    u.serviceimei.api_service.name
                                                    :
                                                    'No api connecti'
                                            }</td>
                                            <td className="default_dg_td tx-left dg_nowrap td_border  bd_left_solid1_f1 bd_lright_solid1_f1">
                                                <input
                                                    className="outline-none input_up"
                                                    value={response[u.id] ? response[u.id].response : ''}
                                                    name={[u.id]}
                                                    placeholder="Response:"
                                                    style={{ width: 250 }}
                                                    onChange={e => toggleHandler({ u, e, status: null })}
                                                />
                                            </td>
                                            <td className="default_dg_td tx-left dg_nowrap td_border  bd_left_solid1_f1 bd_lright_solid1_f1">{parseFloat(u.cost).toFixed(2)}</td>
                                            <td className="default_dg_td tx-center dg_nowrap td_border  bd_left_solid1_f1 bd_lright_solid1_f1">
                                                <input
                                                    className='cursor-pointer'
                                                    value={response[u.id] ? response[u.id].response : ''}
                                                    onChange={(e) => toggleHandler({ u, status: 3, e })}
                                                    checked={response[u.id] && response[u.id].status === 3 ? true : false}
                                                    type="checkbox"
                                                />
                                            </td>
                                            <td className="default_dg_td tx-center dg_nowrap  bd_left_solid1_f1 bd_lright_solid1_f1">
                                                <input
                                                    className='cursor-pointer'
                                                    value={response[u.id] ? response[u.id].response : ''}
                                                    onChange={(e) => toggleHandler({ u, status: 4, e })}
                                                    checked={response[u.id] && response[u.id].status === 4 ? true : false}
                                                    type="checkbox"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className="table-add mg-top_20">
                        <button
                            disabled={loading.update}
                            className="select-none "
                            onClick={() => sendStatus()}
                        >
                            {
                                loading.update ?
                                    <div className="spin update"></div>
                                    :
                                    <div>Submit</div>
                            }
                        </button>
                    </div>
                </div>
            }
            < Paginator
                data={orders}
                action={(p) => getOrders(p)}
            />
        </div>
    );
}

export default AnsweredOrders