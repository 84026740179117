import React, { useState } from 'react'

const ModalDelete = (props) => {
    const { action, isVisible, close } = props
    const [dis, setDis] = useState('disabled');

    const handleModal = (e) => {
        if (e === 'delete') {
            setDis('')
        } else {
            setDis('disabled')
        }
    }

    return (
        <div >
            {
                isVisible &&
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='content'>
                            <h3>
                                Are you sure?
                            </h3>
                            <span>
                                This option is permanent and cannot be remedied,
                                to continue type "delete":
                            </span>
                            <input onChange={(e) => handleModal(e.target.value)} placeholder='delete' />
                            <button onClick={close} className='cursor-pointer close-modal'>X</button>
                            <button onClick={action} disabled={`${dis}`} className={`${dis} delete-modal cursor-pointer`}>Delete</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ModalDelete