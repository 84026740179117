import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import IndexUsers from '../views/users/IndexUsers';
import GetUsers from '../views/users/in-users/GetUsers';
import AddUser from '../views/users/in-users/AddUser';
import IndexUser from '../views/users/in-user/IndexUser';
import UpdateUser from '../views/users/in-user/user/UpdateUser';
import IndexServices from '../views/services/IndexServices';
import GetServices from '../views/services/in-services/GetServices';
import AddService from '../views/services/in-services/AddService';
import GetGroups from '../views/services/groups/GetGroups';
import IndexService from '../views/services/in-service/IndexService';
import UpdateService from '../views/services/in-service/service/UpdateService';
import IndexBanks from '../views/banks/IndexBanks';
import GetBanks from '../views/banks/in-banks/GetBanks';
import AddBank from '../views/banks/in-banks/AddBank';
import IndexBank from '../views/banks/in-bank/IndexBank';
import UpdateBank from '../views/banks/in-bank/bank/UpdateBank';
import ConnectApi from '../views/services/in-service/service/api/ConnectApi';
import IndexApis from '../views/apis/IndexApis';
import GetApis from '../views/apis/in-apis/GetApis';
import AddApi from '../views/apis/in-apis/AddApi';
import IndexApi from '../views/apis/in-api/IndexApi';
import UpdateApi from '../views/apis/in-api/api/UpdateApi';
import IndexImages from '../views/images/IndexImages';
import GetImages from '../views/images/in-images/GetImages';
import AddImage from '../views/images/in-images/AddImage';
import GetCategories from '../views/images/categories/GetCategories';
import IndexCategory from '../views/images/categories/in-category/IndexCategory';
import UpdateCategory from '../views/images/categories/in-category/category/UpdateCategory';
import IndexTickets from '../views/tickets/IndexTickets';
import GetTickets from '../views/tickets/in-tickets/GetTickets';
import BalanceUser from '../views/users/in-user/user/BalanceUser';
import TicketsUser from '../views/users/in-user/user/TicketsUser';
import IndexTicket from '../views/tickets/in-ticket/IndexTicket';
import UpdateTciket from '../views/tickets/in-ticket/ticket/UpdateTciket';
import OrdersUser from '../views/users/in-user/user/OrdersUser';
import AllOrders from '../views/orders/in-orders/AllOrders';
import IndexOrders from '../views/orders/IndexOrders';
import AwaitingOrders from '../views/orders/in-orders/AwaitingOrders';
import AnsweredOrders from '../views/orders/in-orders/AnsweredOrders';
import IndexOrder from '../views/orders/in-order/IndexOrder';
import UpdateOrder from '../views/orders/in-order/order/UpdateOrder';
import Line from '../components/Line';
import ServiceApi from '../views/apis/in-api/api/ServiceApi';
import CustomService from '../views/services/in-service/service/custom/CustomService';
import CustomerGroup from '../views/users/in-users/CustomerGroup';
import IndexCustomer from '../views/users/in-customer/IndexCustomer';
import ImeiPricing from '../views/users/in-customer/customer/ImeiPricing';
import PricingService from '../views/services/in-service/service/pricing/PricingService';
import IndexServers from '../views/services/IndexServers';
import GetServers from '../views/services/in-servers/GetServers';
import AddServer from '../views/services/in-servers/AddServer';
import GetGroupsServer from '../views/services/groups/GetGroupsServer';
import ServerPricing from '../views/users/in-customer/customer/ServerPricing';
import ServerApi from '../views/apis/in-api/api/ServerApi';
import IndexServer from '../views/services/in-server/IndexServer';
import UpdateServer from '../views/services/in-server/server/UpdateServer';
import PricingServer from '../views/services/in-server/server/pricing/PricingServer';
import CustomServer from '../views/services/in-server/server/custom/CustomServer';
import ApiServer from '../views/services/in-server/server/api/ApiServer';
import AllOrdersServer from '../views/orders/in-orders-server/AllOrdersServer';
import IndexOrdersServer from '../views/orders/IndexOrdersServer';
import AnsweredOrdersServer from '../views/orders/in-orders-server/AnsweredOrdersServer';
import AwaitingOrdersServer from '../views/orders/in-orders-server/AwaitingOrdersServer';
import IndexSettings from '../views/settings/IndexSettings';
import GeneralSettings from '../views/settings/GeneralSettings';
import ApiUser from '../views/users/in-user/user/ApiUser';
import ShowItemService from '../views/services/in-service/service/show-item/ShowItemService';
import IndexOrderServer from '../views/orders/in-order/IndexOrderServer';
import UpdateOrderServer from '../views/orders/in-order/order-server/UpdateOrderServer';

const PrivateRoute = () => {
    return (
        <div className='content-prvt dp-flex'>
            <Navbar />
            <div className='all-content dp-flex'>
                <Sidebar />
                <div className='con-tent mg-bottom_100'>
                    <Line />
                    <Routes>
                        <Route path='/' element={<span>Hola</span>} />
                        <Route path='/' element={<IndexUsers />}>
                            <Route path='get-users' element={<GetUsers />} />
                            <Route path='add-user' element={<AddUser />} />
                            <Route path='customer-group' element={<CustomerGroup />} />
                        </Route>
                        <Route path='/' element={<IndexUser />}>
                            <Route path='get-users/user-info' element={<UpdateUser />} />
                            <Route path='get-users/user-balance' element={<BalanceUser />} />
                            <Route path='get-users/user-api' element={<ApiUser />} />
                            <Route path='get-users/user-tickets' element={<TicketsUser />} />
                            <Route path='get-users/user-orders' element={<OrdersUser />} />
                        </Route>
                        <Route path='/' element={<IndexCustomer />}>
                            <Route path='customer-group/imei-pricing' element={<ImeiPricing />} />
                            <Route path='customer-group/server-pricing' element={<ServerPricing />} />
                        </Route>
                        <Route path='/' element={<IndexServices />} >
                            <Route path='get-services' element={<GetServices />} />
                            <Route path='add-service' element={<AddService />} />
                            <Route path='services/get-groups' element={<GetGroups />} />
                        </Route>
                        <Route path='/' element={<IndexServers />} >
                            <Route path='get-servers' element={<GetServers />} />
                            <Route path='add-server' element={<AddServer />} />
                            <Route path='servers/get-groups' element={<GetGroupsServer />} />
                        </Route>
                        <Route path='/' element={<IndexService />} >
                            <Route path='get-services/service-info' element={<UpdateService />} />
                            <Route path='get-services/custom-prices' element={<PricingService />} />
                            <Route path='get-services/show-item' element={<ShowItemService />} />
                            <Route path='get-services/custom-service' element={<CustomService />} />
                            <Route path='get-services/service-api' element={<ConnectApi />} />
                        </Route>
                        <Route path='/' element={<IndexServer />} >
                            <Route path='get-servers/server-info' element={<UpdateServer />} />
                            <Route path='get-servers/custom-prices' element={<PricingServer />} />
                            <Route path='get-servers/custom-server' element={<CustomServer />} />
                            <Route path='get-servers/server-api' element={<ApiServer />} />
                        </Route>
                        <Route path='/' element={<IndexBanks />} >
                            <Route path='get-banks' element={<GetBanks />} />
                            <Route path='add-bank' element={<AddBank />} />
                        </Route>
                        <Route path='/' element={<IndexBank />} >
                            <Route path='get-banks/bank-info' element={<UpdateBank />} />
                        </Route>
                        <Route path='/' element={<IndexApis />} >
                            <Route path='get-apis' element={<GetApis />} />
                            <Route path='add-api' element={<AddApi />} />
                        </Route>
                        <Route path='/' element={<IndexApi />} >
                            <Route path='get-apis/api-info' element={<UpdateApi />} />
                            <Route path='get-apis/api-services' element={<ServiceApi />} />
                            <Route path='get-apis/api-server' element={<ServerApi />} />
                        </Route>
                        <Route path='/' element={<IndexImages />} >
                            <Route path='get-images' element={<GetImages />} />
                            <Route path='add-image' element={<AddImage />} />
                            <Route path='images/get-categories' element={<GetCategories />} />
                        </Route>
                        <Route path='/' element={<IndexCategory />} >
                            <Route path='images/get-categories/category-info' element={<UpdateCategory />} />
                        </Route>
                        <Route path='/' element={<IndexTickets />} >
                            <Route path='get-tickets' element={<GetTickets />} />
                        </Route>
                        <Route path='/' element={<IndexTicket />} >
                            <Route path='get-tickets/ticket-info' element={<UpdateTciket />} />
                        </Route>
                        <Route path='/' element={<IndexOrders />} >
                            <Route path='get-orders' element={<AllOrders />} />
                            <Route path='awaiting-orders' element={<AwaitingOrders />} />
                            <Route path='answered-orders' element={<AnsweredOrders />} />
                        </Route>
                        <Route path='/' element={<IndexOrdersServer />} >
                            <Route path='get-orders-server' element={<AllOrdersServer />} />
                            <Route path='answered-orders-server' element={<AnsweredOrdersServer />} />
                            <Route path='awaiting-orders-server' element={<AwaitingOrdersServer />} />
                        </Route>
                        <Route path='/' element={<IndexOrder />} >
                            <Route path='get-orders/order-info' element={<UpdateOrder />} />
                        </Route>
                        <Route path='/' element={<IndexOrderServer />} >
                            <Route path='get-orders-server/order-info' element={<UpdateOrderServer />} />
                        </Route>
                        <Route path='/' element={<IndexSettings />} >
                            <Route path='general-settings' element={<GeneralSettings />} />
                        </Route>
                    </Routes>
                </div>
            </div>
        </div >
    )
}

export default PrivateRoute