import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import JoditEditor from "jodit-react";
import { useLocation } from "react-router-dom";
import { SetServices } from "../../../store/storeReducers/ServicesReducer";
import { get_groups } from "../../../functions/GroupsServicefunction";
import { create_service } from "../../../functions/Servicefunction";
import { custom, active } from "../../../constants/status";
import { update_Line } from "../../../store/storeReducers/LoadingReducer";

const AddServer = () => {
    const local = useLocation();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [loading, setLoad] = useState(false);
    const [group, setGroup] = useState([]);
    const [error, setErr] = useState({ err: [] });
    const [service, setService] = useState({
        groupimei_id: "",
        name: "",
        description: "",
        cost: "",
        time: '',
        coin: "",
        custom_length: 0,
        length_min: 0,
        length_max: 15,
        only_letters: 0,
        only_numbers: 1,
        bulk: 0,
        protect_order: 1,
        r_custom: '',
        qnt: 0,
        minqnt: 0,
        maxqnt: 0,
        toogle_custom: true,
        connect_api: false,
        api_service_id: '',
        getapi_imei_id: '',
        type: 'SERVER'
    });

    const [array, setArray] = useState({
        "id": '',
        "name": ''
    })

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    useEffect(() => {
        dispatch(update_Line(true));
        if (local.state) {
            setService({
                ...service,
                name: local.state.service_name,
                cost: local.state.service_credit,
                time: local.state.time,
                bulk: local.state.bulk,
                description: local.state.info,
                qnt: local.state.qnt,
                minqnt: local.state.minqnt,
                maxqnt: local.state.maxqnt,
                length_min: local.state.customlen,
                length_max: local.state.maxlength,
                only_letters: local.state.isalpha ? local.state.isalpha : 0,
                only_numbers: 1,
                data_info: local.state.custominfo,
                placeholder: local.state.customname,
                r_custom: local.state.r_custom ? local.state.r_custom : '',
                api_service_id: local.state.api_service_id ? local.state.api_service_id : '',
                getapi_imei_id: local.state.id ? local.state.id : ''
            });
        }
        getGroups();
    }, []);

    const getGroups = async () => {
        const action = {
            action: 'groups',
            type: 'SERVER'
        }
        try {
            const res = await get_groups(action);
            setGroup(res.data.groups);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        });

        setService({
            ...service,
            [e.target.name]: e.target.value
        });
    };

    const toggleChange = (e) => {
        setService({
            ...service,
            [e.target.name]: e.target.checked
        });
    }

    const createService = async () => {
        setLoad(true);
        try {
            const res = await create_service(service);
            alert(res.data.alert)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad(false);
    }

    return (
        <div >
            {
                !line &&
                <div>
                    <table className="table-add" >
                        <tbody>
                            <tr>
                                <td className='fieldlabel'>Group: </td>
                                {
                                    group == "" || group &&
                                    <td className='fieldlabel'>
                                        <select
                                            className='outline-none pd-0-10p'
                                            value={service.groupimei_id}
                                            onChange={(e) => handleChange(e)}
                                            name="groupimei_id"
                                        >
                                            {
                                                [array, ...group].map((g, k) => {
                                                    return (
                                                        <option key={k} value={g.id}>
                                                            {g.name ? g.name : '-Select one group-'}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <div >
                                            <span className="color-red ft-size_11 ft-family-monospace">
                                                {error.groupimei_id}
                                            </span>
                                        </div>
                                    </td>
                                }
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Name:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.name}
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.name}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Default price:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.cost}
                                        name="cost"
                                        type="number"
                                        placeholder="Price:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.cost}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Delivery time:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        defaultValue={service.time}
                                        name='time'
                                        type="text"
                                        placeholder="Time:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Coin:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        value={service.coin}
                                        name="coin"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option>
                                            -Select one option-
                                        </option>
                                        <option value={"MX"}>
                                            MX
                                        </option>
                                        <option value={"USD"}>
                                            USD
                                        </option>
                                    </select>
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.coin}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Active QNT:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='qnt'
                                        onChange={(e) => handleChange(e)}
                                        value={service.qnt}
                                    >
                                        {
                                            custom.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td >QNT min:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.minqnt}
                                        name="minqnt"
                                        type='number'
                                        placeholder="min:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.minqnt}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td >QNT max:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.maxqnt}
                                        name="maxqnt"
                                        type='number'
                                        placeholder="max:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.maxqnt}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            {
                                local.state &&
                                <>
                                    <tr>
                                        <td className="fieldlabel">Coneect api:</td>
                                        <td className="center-objects pst-relative">
                                            <input
                                                type='checkbox'
                                                value={service.connect_api}
                                                name='connect_api'
                                                onChange={(e) => toggleChange(e)}
                                                checked={service.connect_api}
                                            />
                                            <span className="pd-right_20">Save connection</span>
                                        </td>
                                    </tr>
                                    {
                                        local.state.r_custom &&
                                        <tr>
                                            <td className="fieldlabel">Customs important!</td>
                                            <td className="center-objects pst-relative">
                                                <input
                                                    type='checkbox'
                                                    value={service.toogle_custom}
                                                    name='toogle_custom'
                                                    onChange={(e) => toggleChange(e)}
                                                    checked={service.toogle_custom}
                                                />
                                                <span className="pd-right_20">+Add customs</span>
                                            </td>
                                        </tr>
                                    }
                                </>
                            }
                            <tr>
                                <td >
                                    <button
                                        disabled={loading}
                                        className="select-none"
                                        onClick={() => createService()}
                                    >
                                        {
                                            loading ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Add</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ width: '60%' }}>
                        <JoditEditor
                            value={service.description}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setService((state) => ({ ...state, description: newContent }))}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default AddServer;