import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const add_custom = (c) => {
    var url = `${api}/customs`;
    return axiosConfig.post(url, c, {
    }).then(response => response);
}

export const delete_custom = (id) => {
    var url = `${api}/customs/${id}`;
    return axiosConfig.delete(url, {
    }).then(response => response);
}