import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_apis = (u) => {
    var url = u.page ? u.page : `${api}/api_services`;
    return axiosConfig.get(url, {
        params: {
            action: u.action,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}

export const get_apiService = (s) => {
    var url = `${api}/api_services`;
    return axiosConfig.get(`${url}/${s.id}`, {
        params: {
            action: s.action,
            type: s.type
        },
    }).then(response => response);
}

export const create_api = (_api) => {
    var url = `${api}/api_services`;
    return axiosConfig.post(url, _api, {
    }).then(response => response);
}

export const get_api = (u) => {
    var url = u.page ? u.page : `${api}/api_services/${u.id}`;
    return axiosConfig.get(`${url}`, {
        params: {
            action: u.action,
            search: u.search,
            per_page: u.per_page,
            type: u.type
        },
    }).then(response => response);
}


export const update_api = (_api) => {
    var url = `${api}/api_services`;
    return axiosConfig.put(`${url}/${_api.id}`, _api, {
    }).then(response => response);
}

export const delete_api = (id) => {
    var url = `${api}/api_services`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}


export const synchronize_api = (data) => {
    var url = `${api}/synchronize`;
    return axiosConfig.post(url, data, {
    }).then(response => response);
}

export const get_api_services = (u) => {
    var url = u.page ? u.page : `${api}/getServices`;
    return axiosConfig.get(url, {
        params: {
            id: u.id,
            search: u.search
        },
    }).then(response => response);
}

export const get_api_service = (u) => {
    var url = `${api}/getServices`;
    return axiosConfig.get(`${url}/${u.id}`, {
    }).then(response => response);
}
