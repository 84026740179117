import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { get_api } from "../../../../functions/Apifunction";
import Line from "../../../../components/Line";
import { useSelector, useDispatch } from "react-redux";
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";
import Search from "../../../../components/Search";
import Paginator from "../../../../components/Paginator";
import { ImagenStatus } from "../../../../components/ImagenStatus";

const ServiceApi = () => {
    const navigate = useNavigate();
    const param = useOutletContext();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [services, setServices] = useState({ data: [] });

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getServices();
        }
    }, [param.count])

    const getServices = async (value) => {
        try {
            const service = {
                id: param.id,
                action: 'services',
                type: 'IMEI',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_api(service);
            if (res.data.services) {
                setServices(res.data.services);
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getServices(p)}
                        value={"Search by name..."}
                    />
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-left default_dg_a_header">Service api name</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Price</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Custom</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">+Add</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    services.data.map((s, index) => {
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td_create tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{s.service_name}</td>
                                                <td className="default_dg_td_create tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">${s.service_credit}</td>
                                                <td className="default_dg_td_create tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">
                                                    <input
                                                        disabled={!s.r_custom}
                                                        type="checkbox"
                                                        defaultChecked={s.r_custom}
                                                    />
                                                </td>
                                                <td className="default_dg_td_create tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 ">
                                                    <button
                                                        onClick={() => navigate(`/add-service`, { state: s })}
                                                        className='select-none delete buttom_create'
                                                    >
                                                        <span>Create</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={services}
                action={(p) => getServices(p)}
            />
        </div>
    )
}

export default ServiceApi;