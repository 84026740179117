import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_settings = () => {
    var url = `${api}/general`;
    return axiosConfig.get(url, {
    }).then(response => response);
}

export const update_settings = (general) => {
    var url = `${api}/general`;
    return axiosConfig.put(`${url}/${general.id}`, general, {
    }).then(response => response);
}