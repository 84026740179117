import React, { useState, useEffect } from "react";
import ModalDelete from "../../../../../components/ModalDelete";
import { useNavigate, useOutletContext } from "react-router-dom";
import { get_category, update_category, delete_category } from "../../../../../functions/Imagesfunction";
import { useDispatch, useSelector } from 'react-redux';
import { update_Line } from "../../../../../store/storeReducers/LoadingReducer";

const UpdateCategory = () => {
    const param = useOutletContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [loading, setLoad] = useState({
        update: false,
        delete: false,
        modal: false
    });
    const [category, setCat] = useState({
        id: '',
        name: ''
    });

    const handleChange = (e) => {
        setCat({
            ...category,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getCategory(param.id);
        }
    }, [param.count])

    const getCategory = async (id) => {
        try {
            const res = await get_category({ id, action: 'category' });
            if (res.data.category) {
                setCat(res.data.category);
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateCateg = async () => {
        setLoad({ ...loading, update: true });
        try {
            const res = await update_category(category);
            alert(res.data);
        } catch (error) {
            alert(error.response.data);
        }
        setLoad({ ...loading, update: false });
    }

    const deleteCateg = async () => {
        setLoad({ ...loading, modal: false, delete: true });
        try {
            const res = await delete_category(category.id);
            alert(res.data);
            navigate('/images/get-categories');
        } catch (error) {
            alert(error.response.data);
        }
        setLoad({ ...loading, delete: false });
    }

    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({ ...loading, modal: !loading.modal })}
                action={() => deleteCateg()}
            />
            {
                !line &&
                <div>
                    <table className="table-add" >
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        value={category.name}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button
                                        disabled={loading.update}
                                        className="select-none"
                                        onClick={() => updateCateg()}
                                    >
                                        {
                                            loading.update ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Update</div>
                                        }
                                    </button>
                                </td>
                                <td className="active">
                                    <button
                                        disabled={loading.delete}
                                        className="select-none delete"
                                        onClick={() => setLoad({ ...loading, modal: true })}
                                    >
                                        {
                                            loading.delete ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Delete</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default UpdateCategory