import React from 'react';
import { IoSearchCircleOutline } from 'react-icons/io5';


const Search = (props) => {
    return (
        <div className='for-searching'>
            <IoSearchCircleOutline className='glass' />
            <input
                spellCheck={false}
                onChange={(e) => props.action({ search: e.target.value })}
                className='search outline-none ft-family-monospace'
                type={'search'}
                placeholder={props.value}
            />
        </div>
    )
}

export default Search