export const payments = [
    {
        name: '',
        reason: [
            {
                value: false, reason: '-Select reason-'
            }
        ]
    },
    {
        name: 'Spanish',
        reason: [
            {
                value: true, reason: 'Recarga exitosa!'
            },
            {
                value: true, reason: 'Pago realizado!'
            },
            {
                value: true, reason: 'Saldo abonado!'
            },
            {
                value: true, reason: 'Saldo agregado!'
            },
            {
                value: true, reason: 'Saldo cobrado!'
            },
            {
                value: true, reason: 'Recarga reembolsada!'
            },
            {
                value: true, reason: 'Saldo reembolsado!'
            },
            {
                value: true, reason: 'Recarga duplicada!'
            },
            {
                value: true, reason: 'Cantidad incorrecta!'
            }
        ]
    },
    {
        name: 'English',
        reason: [
            {
                value: true, reason: 'Successful recharge!'
            },
            {
                value: true, reason: 'Payment made!'
            },
            {
                value: true, reason: 'Balance paid!'
            },
            {
                value: true, reason: 'Balance added!'
            },
            {
                value: true, reason: 'Balance collected!'
            },
            {
                value: true, reason: 'Recharge refunded!'
            },
            {
                value: true, reason: 'Balance refunded!'
            },
            {
                value: true, reason: 'Duplicate Recharge!'
            },
            {
                value: true, reason: 'Incorrect amount!'
            }
        ]
    }
]

export const items = [
    {
        name: '',
        reason: [
            {
                value: '', reason: '-Select type-'
            }
        ]
    },
    {
        name: 'Spanish',
        reason: [
            {
                value: 'Promo', reason: 'Promo'
            },
            {
                value: 'Oferta', reason: 'Oferta'
            },
            {
                value: 'Caliente', reason: 'Caliente'
            },
            {
                value: '50% descuento', reason: '50% descuento'
            },
        ]
    },
    {
        name: 'English',
        reason: [
            {
                value: 'Offer', reason: 'Offer'
            },
            {
                value: 'Hot', reason: 'Hot'
            },
            {
                value: '50% off', reason: '50% off'
            },
        ]
    }
]