import logoactive from "../assets/green.gif";
import logoinactive from "../assets/red.gif";
import logoproccess from "../assets/pink.gif";
import logowait from "../assets/silver.gif";


export const ImagenStatus = (status) => {
    if (status == 1) {
        var imagen = <img src={logoactive} />
    }
    if (status == 0) {
        var imagen = <img src={logoinactive} />
    }
    return imagen;
}

export const OrderStatus = (status) => {
    if (status == 0) {
        var imagen = <img src={logowait} />
    }
    if (status == 1) {
        var imagen = <img src={logoproccess} />
    }
    if (status == 3) {
        var imagen = <img src={logoinactive} />
    }
    if (status == 4) {
        var imagen = <img src={logoactive} />
    }
    return imagen;
}