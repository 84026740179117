import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../../../api/ConnectApi";
import { get_categories, create_imagen } from "../../../functions/Imagesfunction";
import { useSelector, useDispatch } from "react-redux";
import { update_Line } from "../../../store/storeReducers/LoadingReducer";

const AddImage = () => {
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [image, setImage] = useState({
        category: '',
        name: '',
        uri: ''
    });
    const [loading, setLoad] = useState(false);
    const [error, setErr] = useState({ err: [] })
    const [categories, setCateg] = useState('');
    const [fileUrl, setFileUrl] = useState(null);

    useEffect(() => {
        dispatch(update_Line(true));
        getCateg();
    }, []);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        });
        setImage({
            ...image,
            [e.target.name]: e.target.value
        });
    }

    const processImage = (event) => {
        setImage({
            ...image,
            [event.target.name]: event.target.files[0]
        });
        setErr({
            ...error,
            image: ''
        });
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
    }

    const getCateg = async (value) => {
        try {
            const category = {
                action: 'all_categories'
            };
            const res = await get_categories(category);
            setCateg(res.data.categories);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const upImg = async () => {
        setLoad(true);
        try {
            const up = new FormData();
            up.append('category', image.category)
            up.append('name', image.name)
            up.append('image', image.uri)
            const res = await create_imagen(up);
            alert(res.data.alert)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad(false);
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <table className="table-add" >
                        <tbody>
                            <tr>
                                <td>Image name :</td>
                                <td>
                                    <input
                                        className='outline-none pd-0-10p'
                                        onChange={(e) => handleChange(e)}
                                        autoComplete='new-password'
                                        name='name'
                                        placeholder='Image name:' />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.name}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td >Place an image:</td>
                                <td>
                                    <input
                                        className='outline-none pd-0-10p'
                                        onChange={(e) => processImage(e)}
                                        name='uri'
                                        type={'file'} />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.image}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Category:</td>
                                <td>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name="category"
                                        onChange={(e) => handleChange(e)}
                                        value={image.category}
                                    >
                                        {
                                            categories == '' || categories &&
                                            [{ id: '', name: '--Select category--' }, ...categories].map((c, k) => {
                                                return (
                                                    <option key={k} value={c.id}>
                                                        {c.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.category}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button
                                        disabled={loading}
                                        className="select-none"
                                        onClick={() => upImg()}
                                    >
                                        {
                                            loading ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Add</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <img src={fileUrl} />
                    </div>
                </div>
            }
        </div>
    )
}

export default AddImage