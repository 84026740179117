import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_serviceprice = (u) => {
    var url = u.page ? u.page : `${api}/Serviceprice`;
    return axiosConfig.get(url, {
        params: {
            idcat: u.idcat,
            customer: u.customer
        },
    }).then(response => response);
}


export const update_serviceprice = (serviceprice) => {
    var url = `${api}/Serviceprice`;
    return axiosConfig.put(`${url}/${serviceprice.id}`, serviceprice, {
    }).then(response => response);
}