import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './storeReducers/AuthReducer';
import UsersReducer from './storeReducers/UsersReducer';
import CategoriesReducer from './storeReducers/CategoriesReducer';
import ApisReducer from './storeReducers/ApisReducer';
import BanksReducer from './storeReducers/BanksReducer';
import BillReducer from './storeReducers/BillReducer';
import ServicesReducer from './storeReducers/ServicesReducer';
import GroupsReducer from './storeReducers/GroupsReducer';
import TicketsReducer from './storeReducers/TicketsReducer';
import OrdersReducer from './storeReducers/OrdersReducer';
import LoadingReducer from './storeReducers/LoadingReducer';

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        users: UsersReducer,
        categories: CategoriesReducer,
        apis: ApisReducer,
        banks: BanksReducer,
        bills: BillReducer,
        services: ServicesReducer,
        groups: GroupsReducer,
        tickets: TicketsReducer,
        orders: OrdersReducer,
        loading: LoadingReducer
    },
})