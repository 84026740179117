import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_images = (u) => {
    var url = u.page ? u.page : `${api}/image`;
    return axiosConfig.get(url, {
        params: {
            action: u.action,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}

export const delete_imagen = (id) => {
    var url = `${api}/image`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}

export const get_categories = (u) => {
    var url = u.page ? u.page : `${api}/category`;
    return axiosConfig.get(url, {
        params: {
            action: u.action,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}

export const create_category = (v) => {
    var url = `${api}/category`;
    return axiosConfig.post(`${url}`, v, {
    }).then(response => response);
}

export const create_imagen = (v) => {
    var url = `${api}/image`;
    return axiosConfig.post(`${url}`, v, {
    }).then(response => response);
}

export const get_category = (u) => {
    var url = u.page ? u.page : `${api}/category/${u.id}`;
    return axiosConfig.get(`${url}`, {
        params: {
            action: u.action,
            search: u.search
        },
    }).then(response => response);
}


export const update_category = (category) => {
    var url = `${api}/category`;
    return axiosConfig.put(`${url}/${category.id}`, category, {
    }).then(response => response);
}

export const delete_category = (id) => {
    var url = `${api}/category`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}