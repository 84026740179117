import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    categories: {
        categories: { data: [] }
    },
}

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        SetCategories: (state, action) => {
            state.categories.categories = action.payload
        },
        Update_categories: (state, action) => {
            let b = state.categories.categories.data.map(category => {
                if (category.id !== action.payload.id) {
                    return category;
                } else {
                    return action.payload;
                }
            });
            state.categories.categories = {
                ...state.categories.categories,
                data: b
            }
        },
        Delete_categories: (state, action) => {
            let a = state.categories.categories.data.filter(category => {
                return category.id !== action.payload.id;
            })
            state.categories.categories = {
                ...state.categories.categories,
                data: a
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { SetCategories, Update_categories, Delete_categories } = categoriesSlice.actions

export default categoriesSlice.reducer