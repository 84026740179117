import React, { useEffect, useState } from 'react';
import { create_user } from "../../../functions/Userfunction";
import { countries } from "../../../constants/countries";
import { active } from "../../../constants/status";
import { get_customers } from "../../../functions/Customerfunction";
import { update_Line } from '../../../store/storeReducers/LoadingReducer';
import { useSelector, useDispatch } from "react-redux";


export const AddUser = () => {
    const dispatch = useDispatch();
    const [loading, setLoad] = useState(false);
    const [error, setErr] = useState({ err: [] });
    const [customers, setCustomers] = useState([]);
    const line = useSelector((state) => state.loading.loading.line);
    const [user, setUser] = useState({
        id: '',
        name: '',
        last_name: '',
        email: '',
        password: '',
        address: '',
        country: '',
        phone: '',
        customer_group: '',
        isActive: true
    });

    useEffect(() => {
        dispatch(update_Line(true));
        getCustomers();
    }, []);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const getCustomers = async () => {
        try {
            const custom = {
                action: 'all'
            };
            const res = await get_customers(custom);
            setCustomers(res.data.customers);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const createUser = async () => {
        setLoad(true);
        try {
            const res = await create_user(user);
            alert(res.data.alert)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                } else if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad(false);
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <table className='table-add' >
                        <tbody>
                            <tr>
                                <td className='fieldlabel'>Name:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={user.name}
                                        name='name'
                                        type="email"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.name}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Last Name:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={user.last_name}
                                        name='last_name'
                                        type="email"
                                        placeholder="Last name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.last_name}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Email:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={user.email}
                                        name="email"
                                        type="email"
                                        placeholder="Email:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.email}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Password:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        name="password"
                                        type="password"
                                        placeholder="New password:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.password}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Address:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={user.address}
                                        name="address"
                                        placeholder="Address:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Country:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='country'
                                        onChange={(e) => handleChange(e)}
                                    >
                                        {
                                            [{ name: user && user.country ? user.country : '' }, ...countries].map((p, k) => {
                                                return (
                                                    <option key={k} value={p.name}>
                                                        {p.name ? p.name : '-Select your country-'}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Phone:</td>
                                <td className='fieldlabel'>
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={user.phone}
                                        name="phone"
                                        type="tel"
                                        placeholder="Phone:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Customer group:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='customer_group'
                                        onChange={(e) => handleChange(e)}
                                        value={user.customer_group}
                                    >
                                        {
                                            [{ id: '', groupname: '-Select your group-' }, ...customers].map((customer, index) => {
                                                return (
                                                    <option key={index} value={customer.id}>
                                                        {customer.groupname}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.customer_group}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Active:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='isActive'
                                        onChange={(e) => handleChange(e)}
                                        value={user.isActive}
                                    >
                                        {
                                            active.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <button
                                        disabled={loading}
                                        className='select-none'
                                        onClick={() => createUser()}
                                    >
                                        {
                                            loading ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Add</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );

}

export default AddUser