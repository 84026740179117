import React, { useState } from 'react';
import axios from 'axios';
import { api } from '../../api/ConnectApi';
import { useDispatch } from "react-redux";
import { SetLogin } from '../../store/storeReducers/AuthReducer';
import Logo from '../../assets/ef-transparent.png';

const LoginView = () => {

    const dispatch = useDispatch();
    const [admin, setAdmin] = useState({
        email: '',
        password: ''
    });
    const url = document.domain;
    const [error, setErr] = useState({
        err: []
    });
    const [loading, setLoad] = useState(false);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        });
        setAdmin({
            ...admin,
            [e.target.name]: e.target.value
        })
    }

    const signIn = async () => {
        setLoad(true);
        axios.post(`${api}/login`, admin, {
        }).then(res => {
            if (!res.data.user.isAdmin) {
                return alert('You dont have permissions to access');
            }
            localStorage.setItem('save_auth', JSON.stringify(res.data));
            dispatch(SetLogin(res.data));
            setLoad(false);
        }).catch(err => {
            setLoad(false);
            if (err.response.data && err.response.data.err) {
                setErr(err.response.data.err);
            } else {
                alert(err.response.data.Error);
            }
        })
    }

    return (
        <div className='container'>
            <div className="provider bg-232f3e ">
                <div className="dp-flex">
                    <div className="colll ft-size_13 ft-weight_bold ft-family-monospace">
                        <span className="color-C792EA  ">{`Panel->`}</span>
                        <span className="color-82AAFF ">{`provide->`}</span>
                        <span className="color-C3E88D  ">{`made->`}</span>
                        <span className="color-FF5572  ">by(</span>
                        <span className="color-3dd0f8  ">[eneyvol</span>
                        <span className="color-BEC5D4  ">,</span>
                        <span className="color-FFCB8B ">family]</span>
                        <span className="color-FF5572  ">)</span>
                        <span className="color-BEC5D4">;</span>
                    </div>
                </div>
            </div>
            <div className='order-form cont-center'>
                <div className='form dp-flex'>
                    <div className='tx-center'>
                        <img className="img-ef" src={Logo} />
                        <span className="color-black">@{url}</span>
                    </div>
                    <div className='form-data dp-flex'>
                        <input className='inp-data outline-none bd-none' onChange={(e) => handleChange(e)} name='email' placeholder='Email:' autoComplete="new-password" />
                        <label className='color-err ft-size_10 pd-5'>{error.email}</label>
                    </div>
                    <div className='form-data dp-flex'>
                        <input className='inp-data outline-none bd-none' onChange={(e) => handleChange(e)} name='password' placeholder='*****' type={'password'} autoComplete="new-password" />
                        <label className='color-err ft-size_10 pd-5'>{error.password}</label>
                    </div>
                    <button className='btt-submit cursor-pointer' onClick={() => signIn()}>
                        {
                            loading ?
                                <div className='spin'></div>
                                :
                                <div>Sign In</div>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LoginView