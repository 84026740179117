import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalDelete from "../../../../components/ModalDelete";
import { Update_apis, Delete_apis } from "../../../../store/storeReducers/ApisReducer";
import { useNavigate, useOutletContext } from "react-router-dom";
import { get_api, update_api, delete_api, synchronize_api } from "../../../../functions/Apifunction";
import { conection } from "../../../../constants/conection";
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";

const UpdateApi = () => {
    const dispatch = useDispatch();
    const param = useOutletContext();
    const line = useSelector((state) => state.loading.loading.line);
    const [Services, setSetvices] = useState([]);
    const [err, setErr] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const [loading, setLoad] = useState({
        update: false,
        delete: false,
        sync: false,
        modal: false
    });
    const [api_services, setApi] = useState({
        id: "",
        name: "",
        url: "",
        username: "",
        api_key: "",
        action_api: '',
        belongTo: ''
    });

    const url = document.domain;
    const apis = [
        { name: 'action_1', action: 1 },
        { name: 'action_2', action: 2 },
        { name: 'action_3', action: 3 },
        { name: 'action_4', action: 4 },
        { name: 'action_5', action: 5 },
        { name: 'action_6', action: 6 },
        { name: 'action_7', action: 7 },
        { name: 'action_8', action: 8 },
        { name: 'action_9', action: 9 },
        { name: 'action_10', action: 10 }
    ]

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getApi(param.id);
        }
    }, [param.count])

    const handleChange = (e) => {
        setApi({
            ...api_services,
            [e.target.name]: e.target.value
        });
    };

    const getApi = async (id) => {
        try {
            const res = await get_api({ id, action: 'api' });
            setApi(res.data.api_service);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateApi_Service = async () => {
        setLoad({ ...loading, update: true });
        try {
            const res = await update_api(api_services);
            dispatch(Update_apis(api_services))
            alert(res.data.alert);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const deleteApi_Service = async () => {
        setLoad({ ...loading, modal: false, delete: true });
        try {
            const res = await delete_api(api_services.id);
            dispatch(Delete_apis(api_services))
            alert(res.data.alert);
            navigate('/get-apis');
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, delete: false });
    }

    const _synchronize = async () => {
        const action = { id: api_services.id, action: api_services.belongTo }
        setErr('')
        setSetvices([])
        setLoad({ ...loading, sync: true });
        try {
            const res = await synchronize_api(action);
            setSuccess(res.data.success);
        } catch (err) {
            if (err.response.data && err.response.data.err) {
                setErr(err.response.data.err);
            }
        }
        setLoad({ ...loading, sync: false });
    }

    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({ ...loading, modal: !loading.modal })}
                action={() => deleteApi_Service()}
            />
            {
                !line &&
                <div>
                    <div>
                        <div className='ft-size_11'>Send message to support for active cron job:</div>
                        <span className="color-green ft-size_11 ft-family-monospace">
                            {`/usr/bin/wget -O - -q -t 1 ${url}/${api_services.belongTo}/action_${api_services.action_api}?action=${api_services.action_api}`}
                        </span>
                    </div>
                    <table className="table-add" >
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>
                                    <input
                                        className="outline_none pd-0-10p"
                                        autoComplete="new-password"
                                        defaultValue={api_services.name}
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>URL:</td>
                                <td>
                                    <input
                                        className="outline_none pd-0-10p"
                                        autoComplete="new-password"
                                        defaultValue={api_services.url}
                                        name='url'
                                        type="text"
                                        placeholder="https://:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Username:</td>
                                <td>
                                    <input
                                        className="outline_none pd-0-10p"
                                        autoComplete="new-password"
                                        defaultValue={api_services.username}
                                        name="username"
                                        type="text"
                                        placeholder="Username:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Api Key:</td>
                                <td>
                                    <input
                                        className="outline_none pd-0-10p"
                                        autoComplete="new-password"
                                        defaultValue={api_services.api_key}
                                        name="api_key"
                                        type="text"
                                        placeholder="Api Key:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Provider api:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className="outline_none pd-0-10p"
                                        name='belongTo'
                                        onChange={(e) => handleChange(e)}
                                        disabled={'disabled'}
                                        value={api_services.belongTo}
                                    >
                                        {
                                            conection.map((con, key) => {
                                                return (
                                                    con.reason &&
                                                    <option key={key} value={con.value ? con.reason : ''} >
                                                        {con.reason}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Active api:</td>
                                <td>
                                    <select
                                        className="outline_none pd-0-10p"
                                        name='action_api'
                                        onChange={(e) => handleChange(e)}
                                        value={api_services.action_api}
                                        disabled={'disabled'}
                                    >
                                        {
                                            [{ id: '', name: '-Active api-' }, ...apis].map((a, k) => {
                                                return (
                                                    <option key={k} value={a.action}>
                                                        {a.name}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button
                                        disabled={loading.update}
                                        className="select-none"
                                        onClick={() => updateApi_Service()}
                                    >
                                        {
                                            loading.update ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Update</div>
                                        }
                                    </button>
                                </td>
                                <td className="active">
                                    <button
                                        disabled={loading.delete}
                                        className="select-none delete"
                                        onClick={() => setLoad({ ...loading, modal: true })}
                                    >
                                        {
                                            loading.delete ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Delete</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button
                                        disabled={loading.sync}
                                        className="sync"
                                        onClick={() => _synchronize()}
                                    >
                                        {
                                            loading.sync ?
                                                <span className='spin' />
                                                :
                                                <span> Start sync services api</span>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {err && <span className="ft-size_13 color-err ft-weight_bold">{err}</span>}
                    {success && <span className="ft-size_13 color-green ft-weight_bold">{success}</span>}
                </div>
            }
        </div>
    );
}

export default UpdateApi