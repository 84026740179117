import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    banks: {
        banks: { data: [] }
    }
}

export const banksSlice = createSlice({
    name: 'banks',
    initialState,
    reducers: {
        SetBanks: (state, action) => {
            state.banks.banks = action.payload;
        },
        Update_banks: (state, action) => {
            let b = state.banks.banks.data.map(bank => {
                if (bank.id !== action.payload.id) {
                    return bank;
                } else {
                    return action.payload;
                }
            });
            state.banks.banks = {
                ...state.banks.banks,
                data: b
            }
        },
        Delete_banks: (state, action) => {
            let a = state.banks.banks.data.filter(bank => {
                return bank.id !== action.payload.id;
            })
            state.banks.banks = {
                ...state.banks.banks,
                data: a
            }
        },
    }
})


export const { SetBanks, Update_banks, Delete_banks } = banksSlice.actions

export default banksSlice.reducer