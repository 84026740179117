import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import ModalDelete from "../../../../components/ModalDelete";
import JoditEditor from "jodit-react";
import { Update_services, Delete_services } from "../../../../store/storeReducers/ServicesReducer";
import { custom, active } from "../../../../constants/status";
import { get_groups } from "../../../../functions/GroupsServicefunction";
import { get_service, update_service, delete_service } from "../../../../functions/Servicefunction";
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";

const UpdateService = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const param = useOutletContext();
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState({
        update: false,
        delete: false,
        modal: false
    });
    const [group, setGroup] = useState("");
    const [service, setService] = useState({});

    const [array, setArray] = useState({
        "id": '',
        "name": ''
    })

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getService(param.id);
        }
    }, [param.count]);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        });

        setService({
            ...service,
            [e.target.name]: e.target.value
        });
    };

    const getGroups = async () => {
        try {
            const res = await get_groups({ action: 'groups', type: 'IMEI' });
            setGroup(res.data.groups);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const getService = async (id) => {
        try {
            const res = await get_service({ id, action: 'info' });
            setService(res.data.service);
        } catch (error) {
            alert(error.response.data);
        }
        await getGroups();
    }

    const updateService = async () => {
        setLoad({ ...loading, update: true });
        try {
            service.action = 'data-info';
            const res = await update_service(service);
            dispatch(Update_services(res.data.service))
            alert(res.data.success);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const deleteService = async () => {
        setLoad({ ...loading, modal: false, delete: true });
        try {
            const res = await delete_service(service.id);
            dispatch(Delete_services(service));
            alert(res.data.alert)
            navigate('/get-services');
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, delete: false });
    }

    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({ ...loading, modal: !loading.modal })}
                action={() => deleteService()}
            />
            {
                !line &&
                <div>
                    <table className="table-add" >
                        <tbody>
                            <tr>
                                <td >Group: </td>
                                <td >
                                    <select
                                        className='outline-none pd-0-10p'
                                        value={service.groupimei_id}
                                        onChange={(e) => handleChange(e)}
                                        name="groupimei_id"
                                    >
                                        {
                                            [array, ...group].map((g, k) => {
                                                return (
                                                    <option key={k} value={g.id}>
                                                        {g.name ? g.name : '-Select one group-'}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td >Name:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.name}
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >Active:</td>
                                <td >
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='isActive'
                                        onChange={(e) => handleChange(e)}
                                        value={service.isActive}
                                    >
                                        {
                                            active.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td >Default price:</td>
                                <td className='fieldlabel'>
                                    <span
                                        className='outline-none pd-0-10p'>
                                        {(parseFloat(service.cost).toFixed(2) || (0).toFixed(2))}
                                    </span>
                                </td>
                                <td className='fieldlabel'>
                                    <button
                                        onClick={() => navigate(`/get-services/custom-prices?reference_id=${param.id}`)}
                                        className='select-none delete buttom_up '
                                    >
                                        <span>service pricing</span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td >Delivery time:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        defaultValue={service.time}
                                        name='time'
                                        type="text"
                                        placeholder="Time:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >Position:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        defaultValue={service.position}
                                        name='position'
                                        type="number"
                                        placeholder="Position:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >Coin:</td>
                                <td >
                                    <select
                                        className='outline-none pd-0-10p'
                                        value={service.coin}
                                        name="coin"
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option>
                                            -Select one option-
                                        </option>
                                        <option value={"MX"}>
                                            MX
                                        </option>
                                        <option value={"USD"}>
                                            USD
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Protect order:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='protect_order'
                                        onChange={(e) => handleChange(e)}
                                        value={service.protect_order}
                                    >
                                        {
                                            custom.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='fieldlabel'>Bulk:</td>
                                <td className='fieldlabel'>
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='bulk'
                                        onChange={(e) => handleChange(e)}
                                        value={service.bulk}
                                    >
                                        {
                                            custom.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td >Custom lenght:</td>
                                <td >
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='custom_length'
                                        onChange={(e) => handleChange(e)}
                                        value={service.custom_length}
                                    >
                                        {
                                            custom.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            {
                                service.custom_length == true &&
                                <>
                                    <tr>
                                        <td >Lenght min:</td>
                                        <td >
                                            <input
                                                className='outline-none pd-0-10p'
                                                autoComplete="new-password"
                                                defaultValue={service.length_min}
                                                name="length_min"
                                                type='number'
                                                placeholder="min:"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <div >
                                                <span className="color-red ft-size_11 ft-family-monospace">
                                                    {error.length_min}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >Lenght max:</td>
                                        <td >
                                            <input
                                                className='outline-none pd-0-10p'
                                                autoComplete="new-password"
                                                defaultValue={service.length_max}
                                                name="length_max"
                                                type='number'
                                                placeholder="max:"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <div >
                                                <span className="color-red ft-size_11 ft-family-monospace">
                                                    {error.length_max}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >Only letters:</td>
                                        <td >
                                            <select
                                                className='outline-none pd-0-10p'
                                                name='only_letters'
                                                onChange={(e) => handleChange(e)}
                                                value={service.only_letters}
                                            >
                                                {
                                                    custom.map((a, k) => {
                                                        return (
                                                            <option key={k} value={a.isActive}>
                                                                {a.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >Only numbers:</td>
                                        <td >
                                            <select
                                                className='outline-none pd-0-10p'
                                                name='only_numbers'
                                                onChange={(e) => handleChange(e)}
                                                value={service.only_numbers}
                                            >
                                                {
                                                    custom.map((a, k) => {
                                                        return (
                                                            <option key={k} value={a.isActive}>
                                                                {a.name}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <td >Data info:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.data_info}
                                        name="data_info"
                                        type='text'
                                        placeholder=". . . ."
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >Placeholder:</td>
                                <td >
                                    <input
                                        className='outline-none pd-0-10p'
                                        autoComplete="new-password"
                                        defaultValue={service.placeholder}
                                        name="placeholder"
                                        type='text'
                                        placeholder=". . . ."
                                        onChange={(e) => handleChange(e)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td >
                                    <button
                                        disabled={loading.update}
                                        onClick={() => updateService()}
                                    >
                                        {
                                            loading.update ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Update</div>
                                        }
                                    </button>
                                </td>
                                <td className="active" >
                                    <button
                                        disabled={loading.delete}
                                        className="delete"
                                        onClick={() => setLoad({ ...loading, modal: true })}
                                    >
                                        {
                                            loading.delete ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Delete</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ width: '60%' }}>
                        <JoditEditor
                            value={service.description}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setService((state) => ({ ...state, description: newContent }))}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default UpdateService