import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import { update_Line } from '../../../store/storeReducers/LoadingReducer';
import { get_customers, create_customer, update_customer, delete_customer } from '../../../functions/Customerfunction';
import { useSelector, useDispatch } from "react-redux";
import Paginator from '../../../components/Paginator';
import Search from '../../../components/Search';
import { AiOutlineEdit } from "react-icons/ai";
import moment from 'moment';
import ModalDelete from '../../../components/ModalDelete';

const CustomerGroup = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const [customers, setCustomers] = useState({ data: [] });
    const [customer, setCustomer] = useState({
        id: '',
        name: ''
    });
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState({
        add: false,
        update: false,
        delete: false
    });

    useEffect(() => {
        dispatch(update_Line(true));
        getCustomers();
    }, []);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        setCustomer({
            ...customer,
            [e.target.name]: e.target.value
        })
    }

    const getCustomers = async (value) => {
        try {
            const custom = {
                action: 'customer',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_customers(custom);
            setCustomers(res.data.customers);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const creatCustomer = async () => {
        setLoad({ ...loading, add: true });
        try {
            const res = await create_customer(customer);
            setCustomers(res.data.customers)
            alert(res.data.alert)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, add: false });
    }

    const updateCustomer = async (value) => {
        setLoad({ ...loading, update: true })
        try {
            const res = await update_customer(value);
            alert(res.data.alert);
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false })
    }

    const _delete = (id) => {
        setCustomer({
            ...customer,
            ['id']: id
        });
        setLoad({ ...loading, modal: true });
    }

    const deleteCustomer = async () => {
        setLoad({ ...loading, modal: false, delete: true });
        try {
            const res = await delete_customer(customer.id);
            if (res.data) {
                let a = customers.data.filter(c => {
                    return c.id !== customer.id;
                })
                setCustomers({
                    ...customers,
                    data: a
                });
            }
            alert(res.data.alert);
        } catch (error) {
            if (error.response.data) {
                if (error.response.data.alert) {
                    alert(error.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, delete: false, modal: false });
    }

    const toggleHandler = (item) => {
        let newArray = [...customers.data];
        newArray[item.index].groupname = item.e.target.value
        setCustomers({ ...customers, data: newArray });
    };

    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({ ...loading, modal: !loading.modal })}
                action={() => deleteCustomer()}
            />
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getCustomers(p)}
                        value={"Search by email..."}
                    />
                    <table className="table-add balance" >
                        <thead>
                            <tr>
                                <td className="fieldlabel">Creat new group:</td>
                                <td className="fieldlabel">
                                    <input
                                        className='outline-none pd-0-10p'
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.name}
                                        </span>
                                    </div>
                                </td>
                                <td className="fieldlabel">
                                    <button
                                        disabled={loading.add}
                                        onClick={() => creatCustomer()}
                                    >
                                        {
                                            loading.add ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Add</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-left default_dg_a_header">Group name</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Change</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Imei</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customers.data.map((customer, index) => {
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-left dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                    <input
                                                        type={'text'}
                                                        className='input_up'
                                                        defaultValue={customer.groupname}
                                                        onChange={e => toggleHandler({ e, customer, index })}
                                                    />
                                                </td>
                                                <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                    <button
                                                        disabled={loading.update}
                                                        onClick={() => updateCustomer(customer)}
                                                        className='select-none delete buttom_up '
                                                    >
                                                        {
                                                            loading.update ?
                                                                <div className="spin update"></div>
                                                                :
                                                                <div>Update</div>
                                                        }
                                                    </button>
                                                    {
                                                        customer.delete ?
                                                            <button
                                                                disabled={loading.delete}
                                                                onClick={() => _delete(customer.id)}
                                                                className='select-none delete buttom_delete mg-left_10'
                                                            >
                                                                {
                                                                    loading.delete ?
                                                                        <div className="spin update"></div>
                                                                        :
                                                                        <div>Delete</div>
                                                                }
                                                            </button>
                                                            :
                                                            ''
                                                    }
                                                </td>
                                                <td className="default_dg_td tx-center  dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 cursor-pointer">
                                                    <button
                                                        onClick={() => navigate(`/customer-group/imei-pricing?profile_id=${customer.id}`)}
                                                        className='select-none delete buttom_up '
                                                    >
                                                        <span>Imei pricing</span>
                                                    </button>
                                                    <button
                                                        onClick={() => navigate(`/customer-group/server-pricing?profile_id=${customer.id}`)}
                                                        className='select-none delete buttom_up mg-left_10'
                                                    >
                                                        <span>Server pricing</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={customers}
                action={(p) => getCustomers(p)}
            />
        </div>
    )
}

export default CustomerGroup;