import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import moment from 'moment'
import axios from "axios";
import { api } from '../../../../api/ConnectApi';
import { AiOutlineEdit } from "react-icons/ai";
import { OrderStatus } from '../../../../components/ImagenStatus';
import Paginator from '../../../../components/Paginator';
import Search from '../../../../components/Search';
import { get_user } from '../../../../functions/Userfunction';
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";

const TicketsUser = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const [tickets, setTickets] = useState({ data: [] });

    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getTickets();
        }
    }, [param.count]);


    const getTickets = async (value) => {
        try {
            const ticket = {
                id: param.id,
                action: 'tickets',
                search: value && value.search ? value.search : '',
                per_page: value && value.per_page ? value.per_page : 50,
                page: value && value.page ? value.page : '',
            };
            const res = await get_user(ticket);
            setTickets(res.data.tickets);
        } catch (error) {
        }
        dispatch(update_Line(false));
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getTickets(p)}
                        value={"Search by reference..."}
                    />
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Amount</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Status</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Register day</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tickets.data.map((ticket, index) => {
                                        const status = OrderStatus(parseFloat(ticket.status));
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">#{ticket.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{parseFloat(ticket.amount).toFixed(2)}</td>
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{status}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1"> {moment(ticket.created_at).format('Y-MM-DD')}</td>
                                                <td onClick={() => navigate(`/get-tickets/ticket-info?ticket_id=${ticket.id}`)} className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 cursor-pointer">< AiOutlineEdit /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={tickets}
                action={(p) => getTickets(p)}
            />
        </div>
    );

}

export default TicketsUser