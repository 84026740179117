import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SetApis } from "../../../store/storeReducers/ApisReducer";
import { create_api } from "../../../functions/Apifunction";
import { conection } from "../../../constants/conection";

const AddApi = () => {
    const dispatch = useDispatch();
    const [loading, setLoad] = useState(false);
    const [error, setErr] = useState({ err: [] });
    const [api_services, SetApi] = useState({
        name: "",
        url: "",
        username: "",
        api_key: "",
        action_api: '',
        belongTo: ''
    });

    const apis = [
        { name: 'action_1', action: 1 },
        { name: 'action_2', action: 2 },
        { name: 'action_3', action: 3 },
        { name: 'action_4', action: 4 },
        { name: 'action_5', action: 5 },
        { name: 'action_6', action: 6 },
        { name: 'action_7', action: 7 },
        { name: 'action_8', action: 8 },
        { name: 'action_9', action: 9 },
        { name: 'action_10', action: 10 }
    ]

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        SetApi({
            ...api_services,
            [e.target.name]: e.target.value
        });
    };

    const createApi_Service = async () => {
        setLoad(true);
        try {
            const res = await create_api(api_services);
            alert(res.data.alert)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad(false);
    }

    return (
        <div>
            <table className="table-add" >
                <tbody>
                    <tr>
                        <td>Name:</td>
                        <td>
                            <input
                                className="outline_none pd-0-10p"
                                autoComplete="new-password"
                                name='name'
                                type="text"
                                placeholder="Name:"
                                onChange={(e) => handleChange(e)}
                            />
                            <div >
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.name}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>URL:</td>
                        <td>
                            <input
                                className="outline_none pd-0-10p"
                                autoComplete="new-password"
                                name='url'
                                type="text"
                                placeholder="https://:"
                                onChange={(e) => handleChange(e)}
                            />
                            <div >
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.url}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Username:</td>
                        <td>
                            <input
                                className="outline_none pd-0-10p"
                                autoComplete="new-password"
                                name="username"
                                type="text"
                                placeholder="Username:"
                                onChange={(e) => handleChange(e)}
                            />
                            <div >
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.username}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Api Key:</td>
                        <td>
                            <input
                                className="outline_none pd-0-10p"
                                autoComplete="new-password"
                                name="api_key"
                                type="text"
                                placeholder="Api Key:"
                                onChange={(e) => handleChange(e)}
                            />
                            <div >
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.api_key}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='fieldlabel'>Provider api:</td>
                        <td className='fieldlabel'>
                            <select
                                className="outline_none pd-0-10p"
                                name='belongTo'
                                onChange={(e) => handleChange(e)}
                                value={api_services.belongTo}
                            >
                                {
                                    conection.map((con, key) => {
                                        return (
                                            con.reason &&
                                            <option key={key} value={con.value ? con.reason : ''} >
                                                {con.reason}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                            <div >
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.belongTo}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Active api:</td>
                        <td>
                            <select
                                className="outline_none pd-0-10p"
                                name='action_api'
                                onChange={(e) => handleChange(e)}
                                value={api_services.action_api}
                            >
                                {
                                    [{ id: '', name: '-Active api-' }, ...apis].map((a, k) => {
                                        return (
                                            <option key={k} value={a.action}>
                                                {a.name}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                            <div >
                                <span className="color-red ft-size_11 ft-family-monospace">
                                    {error.action_api}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <button
                                disabled={loading}
                                className="select-none"
                                onClick={() => createApi_Service()}
                            >
                                {
                                    loading ?
                                        <div className="spin update"></div>
                                        :
                                        <div>Add</div>
                                }
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default AddApi