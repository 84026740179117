import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Update_users } from "../../../../store/storeReducers/UsersReducer";
import { get_user, update_user } from "../../../../functions/Userfunction";
import { useOutletContext } from "react-router-dom";
import { SetTickets } from "../../../../store/storeReducers/TicketsReducer";
import { active } from "../../../../constants/status";
import { payments } from "../../../../constants/payments";
import { update_Line } from "../../../../store/storeReducers/LoadingReducer";

const ApiUser = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [loading, setLoad] = useState({
        update: false
    });
    const [error, setErr] = useState({ err: [] });
    const [ticket, setTicket] = useState(true);
    const [user, setUser] = useState({
        id: '',
        api_access: '',
        api_key: '',
        api_ip: ''
    });


    useEffect(() => {
        dispatch(update_Line(true));
        if (param && param.id) {
            getUser(param.id);
        }
    }, [param.count]);

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })

    }

    const getUser = async (id) => {
        try {
            const res = await get_user({ id, action: 'balance' });
            if (res.data.user) {
                setUser(res.data.user);
                dispatch(Update_users(res.data.user));
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateUser = async (action) => {
        setLoad({ ...loading, update: true });
        try {
            const dataUser = { ...user, action }
            const res = await update_user(dataUser);
            if (res.data) {
                if (res.data.user) {
                    dispatch(Update_users(res.data.user));
                    setUser(res.data.user);
                }
                if (res.data.api) {
                    setUser({ ...user, api_key: res.data.api });
                }
                alert(res.data.alert);
            }
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                } else if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }


    return (
        <div>
            {
                !line &&
                <table className="table-add" >
                    <tbody>
                        <tr>
                            <td >Active:</td>
                            <td >
                                <select
                                    className='outline-none pd-0-10p'
                                    name='api_access'
                                    onChange={(e) => handleChange(e)}
                                    value={user.api_access}
                                >
                                    {
                                        active.map((a, k) => {
                                            return (
                                                <option key={k} value={a.isActive}>
                                                    {a.name}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </td>
                        </tr>
                        {
                            user.api_access == true &&
                            <>
                                <tr>
                                    <td >Api key:</td>
                                    <td className='fieldlabel wd_300i'>
                                        <div
                                            className='outline-none input_up user-select'>
                                            <span>{user.api_key}</span>
                                        </div>
                                    </td>
                                    <td >
                                        <button
                                            disabled={loading.generate}
                                            className='select-none'
                                            onClick={() => updateUser('api')}
                                        >
                                            <div>Generator</div>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td >Ip address:</td>
                                    <td className='fieldlabel '>
                                        <div
                                            className='outline-none input_up user-select'>
                                            {user.api_ip}
                                        </div>
                                    </td>
                                    <td >
                                        <button
                                            className='select-none'
                                            onClick={() => setUser({ ...user, api_ip: '' })}
                                        >
                                            <div>Reset</div>
                                        </button>
                                    </td>
                                </tr>
                            </>
                        }
                        <tr>
                            <td>
                                <button
                                    disabled={loading.update}
                                    onClick={() => updateUser('info')}
                                >
                                    {
                                        loading.update ?
                                            <div className="spin update"></div>
                                            :
                                            <div>Save</div>
                                    }
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    )
}

export default ApiUser;