import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnectApi';

export const get_services = (u) => {
    var url = u.page ? u.page : `${api}/serviceimei`;
    return axiosConfig.get(url, {
        params: {
            action: u.action,
            search: u.search,
            per_page: u.per_page,
            type: u.type
        },
    }).then(response => response);
}

export const get_service = (service) => {
    var url = `${api}/serviceimei`;
    return axiosConfig.get(`${url}/${service.id}`, {
        params: {
            action: service.action
        },
    }).then(response => response);
}

export const create_service = (v) => {
    var url = `${api}/serviceimei`;
    return axiosConfig.post(`${url}`, v, {
    }).then(response => response);
}

export const update_service = (service) => {
    var url = `${api}/serviceimei`;
    return axiosConfig.put(`${url}/${service.id}`, service, {
    }).then(response => response);
}

export const delete_service = (id) => {
    var url = `${api}/serviceimei`;
    return axiosConfig.delete(`${url}/${id}`, {
    }).then(response => response);
}

