import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from 'react-router-dom';
import { get_service, update_service } from '../../../../../functions/Servicefunction';
import { active } from "../../../../../constants/status";
import { Update_services } from "../../../../../store/storeReducers/ServicesReducer";
import { update_Line } from '../../../../../store/storeReducers/LoadingReducer';
import { items } from '../../../../../constants/payments';

const ShowItemService = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState(false);
    const [service, setService] = useState(
        {
            id: '',
            show_active: '',
            getapi_imei_id: '',
            show_item: null
        }
    );

    useEffect(() => {
        dispatch(update_Line(true));
        getService(param.id);
    }, [param.count])

    const getService = async (id) => {
        try {
            const res = await get_service({ id, action: 'show' });
            setService(res.data.service);
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateService = async () => {
        setLoad(true);
        try {
            service.action = 'data-info';
            const res = await update_service(service);
            dispatch(Update_services(res.data.service))
            alert(res.data.success);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad(false);
    }

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        setService({
            ...service,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <table className='table-add' >
                        <tbody>
                            <tr>
                                <td >Active:</td>
                                <td >
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='show_active'
                                        onChange={(e) => handleChange(e)}
                                        value={service.show_active}
                                    >
                                        {
                                            active.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            {
                                service.show_active == true &&
                                <>
                                    <tr className='dg_tr'>
                                        <td> Item: </td>
                                        <td >
                                            <span>
                                                <select
                                                    value={service.show_item || ''}
                                                    name='show_item'
                                                    onChange={(e) => handleChange(e)}
                                                    className='pd-0-10p outline-none'
                                                >
                                                    {
                                                        items.map((t, index) => {
                                                            return (
                                                                <optgroup label={t.name} key={index}>
                                                                    {
                                                                        t.reason.map((it, k) => {
                                                                            return (
                                                                                <option key={k} value={it.value}>
                                                                                    {it.reason}
                                                                                </option>
                                                                            )
                                                                        })
                                                                    }
                                                                </optgroup>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </span>
                                            <div >
                                                <span className="color-red ft-size_11 ft-family-monospace">
                                                    {error.fieldtype}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>customize item:</td>
                                        <td>
                                            <input
                                                value={service.show_item || ''}
                                                placeholder='Item:'
                                                name='show_item'
                                                onChange={(e) => handleChange(e)}
                                                className='outline-none pd-0-10p'
                                            />
                                        </td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <td className="fieldlabel">
                                    <button
                                        onClick={() => updateService()}
                                        className="bg_313c42"
                                    >
                                        {
                                            loading ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Save</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div >
    )
}

export default ShowItemService