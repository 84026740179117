import React, { useState, useEffect } from 'react';
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api/ConnectApi";
import axios from "axios";
import moment from 'moment'
import { OrderStatus } from "../../../components/ImagenStatus";
import Paginator from "../../../components/Paginator";
import { SetOrdersServer } from "../../../store/storeReducers/OrdersReducer";
import Search from '../../../components/Search';
import { get_orders } from '../../../functions/Ordersfunction';
import { update_Line } from '../../../store/storeReducers/LoadingReducer';

const AllOrdersServer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const orders = useSelector((state) => state.orders.orders.orderServer);

    useEffect(() => {
        if (orders && !orders.data[0]) {
            dispatch(update_Line(true));
            getOrders();
        }
    }, []);

    const getOrders = async (value) => {
        try {
            const order = {
                action: 'orders',
                type: 'SERVER',
                search: value && value.search ? value.search : '',
                per_page: value && value.per_page ? value.per_page : 50,
                page: value && value.page ? value.page : ''
            };
            const res = await get_orders(order);
            dispatch(SetOrdersServer(res.data.orders));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getOrders(p)}
                        value={"Search by imei..."}
                    />
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Service</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">User</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Api</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Status</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Price</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Day</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orders.data.map((ord, index) => {
                                        const status = OrderStatus(parseFloat(ord.status));
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">#{ord.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 white_space">{ord.serviceimei.name}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{ord.user.name}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">  {
                                                    ord.serviceimei.isApi && ord.serviceimei.api_service_id ?
                                                        ord.serviceimei.api_service.name
                                                        :
                                                        'No api connecti'
                                                }</td>
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1"> {status}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">{parseFloat(ord.cost).toFixed(2)}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1"> {moment(ord.created_at).format('Y-MM-DD')}</td>
                                                <td onClick={() => navigate(`order-info?reference_id=${ord.id}`)} className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1 cursor-pointer">< AiOutlineEdit /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={orders}
                action={(p) => getOrders(p)}
            />
        </div>
    );
}

export default AllOrdersServer