import React, { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api/ConnectApi";
import axios from "axios";
import moment from 'moment'
import { ImagenStatus } from "../../../components/ImagenStatus";
import { SetGroupsServer, Update_groupsServer, DeleteGroupsServer } from "../../../store/storeReducers/GroupsReducer";
import Paginator from "../../../components/Paginator";
import Search from "../../../components/Search";
import { get_groups, create_group, update_group, delete_group } from "../../../functions/GroupsServicefunction";
import { update_Line } from '../../../store/storeReducers/LoadingReducer';
import ModalDelete from "../../../components/ModalDelete";


const GetGroupsServer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const line = useSelector((state) => state.loading.loading.line);
    const groups = useSelector((state) => state.groups.groups.groupserver);
    const [group, setGroup] = useState({
        id: '',
        name: '',
        type: 'SERVER'
    });
    const [error, setErr] = useState({ err: [] });
    const [loading, setLoad] = useState({
        add: false,
        update: false,
        delete: false
    });


    useEffect(() => {
        if (groups && !groups.data[0]) {
            dispatch(update_Line(true));
            getGroups();
        }
    }, []);

    const getGroups = async (value) => {
        try {
            const group = {
                action: 'all',
                type: 'SERVER',
                per_page: value && value.per_page ? value.per_page : 50,
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
            };
            const res = await get_groups(group);
            dispatch(SetGroupsServer(res.data.groups));
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const createGroup = async () => {
        setLoad({ ...loading, add: true });
        try {
            const res = await create_group(group);
            alert(res.data.alert)
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, add: false });
        await getGroups();
    }

    const updateGroup = async (value) => {
        setLoad({ ...loading, update: true });
        try {
            const res = await update_group(value);
            dispatch(Update_groupsServer(value))
            alert(res.data.alert);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, update: false });
    }

    const deleteGroup = async () => {
        setLoad({ ...loading, modal: false, delete: true });
        try {
            const res = await delete_group(group.id);
            dispatch(DeleteGroupsServer(group))
            alert(res.data.alert);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setLoad({ ...loading, delete: false });
    }

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        setGroup({
            ...group,
            [e.target.name]: e.target.value
        })
    }

    const toggleHandler = (item) => {
        let newArray = [...groups.data];
        newArray[item.index] = {
            id: newArray[item.index].id,
            name: item.action == 'name' ? item.e.target.value : newArray[item.index].name,
            isActive: item.action == 'check' ? item.e.target.checked : newArray[item.index].isActive,
            position: item.action == 'number' ? item.e.target.value : newArray[item.index].position,
        }
        dispatch(Update_groupsServer(newArray[item.index]));
    };

    const _delete = (value) => {
        setGroup({
            ...group,
            ['id']: value
        })
        setLoad({ ...loading, modal: true });
    }

    return (
        <div>
            <ModalDelete
                isVisible={loading.modal}
                close={() => setLoad({ ...loading, modal: false })}
                action={() => deleteGroup()}
            />
            {
                !line &&
                <div>
                    <Search
                        action={(p) => getGroups(p)}
                        value={"Search by name..."}
                    />
                    <table className="table-add balance" >
                        <thead>
                            <tr>
                                <td className="fieldlabel">Creat new group:</td>
                                <td className="fieldlabel">
                                    <input
                                        className='outline-none pd-0-10p'
                                        name='name'
                                        type="text"
                                        placeholder="Name:"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <div >
                                        <span className="color-red ft-size_11 ft-family-monospace">
                                            {error.name}
                                        </span>
                                    </div>
                                </td>
                                <td className="fieldlabel">
                                    <button
                                        disabled={loading.add}
                                        onClick={() => createGroup()}
                                    >
                                        {
                                            loading.add ?
                                                <div className="spin update"></div>
                                                :
                                                <div>Add</div>
                                        }
                                    </button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <div>
                        <table className="default_dg_table">
                            <thead>
                                <tr>
                                    <th className="default_dg_td tx-center default_dg_a_header">Id</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Name</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Active</th>
                                    <th className="default_dg_td tx-left default_dg_a_header">Position</th>
                                    <th className="default_dg_td tx-center default_dg_a_header">Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    groups.data.map((group, index) => {
                                        return (
                                            <tr key={index} className="dg_tr">
                                                <td className="default_dg_td tx-center dg_nowrap bd_left_solid1_f1 bd_lright_solid1_f1">#{group.id}</td>
                                                <td className="default_dg_td tx-left dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                    <input
                                                        type={'text'}
                                                        className='input_up'
                                                        value={group.name}
                                                        onChange={(e) => toggleHandler({ e, group, index, action: 'name' })}
                                                    />
                                                </td>
                                                <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                    <input
                                                        type="checkbox"
                                                        checked={group.isActive}
                                                        onChange={e => toggleHandler({ e, group, index, action: 'check' })}
                                                    />
                                                </td>
                                                <td className="default_dg_td tx-left dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                    <input
                                                        type={'number'}
                                                        className='input_up'
                                                        value={group.position}
                                                        onChange={(e) => toggleHandler({ e, group, index, action: 'number' })}
                                                    />
                                                </td>
                                                <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-right_solid1_f1">
                                                    <button
                                                        disabled={loading.update}
                                                        onClick={() => updateGroup(group)}
                                                        className='select-none delete buttom_up '
                                                    >
                                                        {
                                                            loading.update ?
                                                                <div className="spin update"></div>
                                                                :
                                                                <div>Update</div>
                                                        }
                                                    </button>
                                                    <button
                                                        disabled={loading.delete}
                                                        onClick={() => _delete(group.id)}
                                                        className='select-none delete buttom_delete mg-left_10'
                                                    >
                                                        {
                                                            loading.delete ?
                                                                <div className="spin update"></div>
                                                                :
                                                                <div>Delete</div>
                                                        }
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            < Paginator
                data={groups}
                action={(p) => getGroups(p)}
            />
        </div>
    );

}

export default GetGroupsServer;