import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useOutletContext } from 'react-router-dom';
import { get_service, update_service } from '../../../../../functions/Servicefunction';
import { active } from "../../../../../constants/status";
import { get_apis, get_apiService, get_api_service } from '../../../../../functions/Apifunction';
import { update_Line } from '../../../../../store/storeReducers/LoadingReducer';

const ConnectApi = () => {
    const param = useOutletContext();
    const dispatch = useDispatch();
    const line = useSelector((state) => state.loading.loading.line);
    const [error, setErr] = useState({ err: [] });
    const [apiService, setApiService] = useState([]);
    const [services_curl, setServices_curl] = useState([]);
    const [curlService, setCurl] = useState('');
    const [service, setService] = useState(
        {
            id: '',
            api_service_id: '',
            isApi: '',
            getapi_imei_id: ''
        }
    );

    useEffect(() => {
        dispatch(update_Line(true));
        getService(param.id);
        getApi_Service();
    }, [param.count])

    const handleChange = (e) => {
        setErr({
            ...error,
            [e.target.name]: ''
        })
        if (e.target.name === 'api_service_id') {
            return (
                setService({
                    ...service,
                    [e.target.name]: e.target.value,
                    ['getapi_imei_id']: ''
                }),
                getServices_curl(e.target.value)
            )
        }
        setService({
            ...service,
            [e.target.name]: e.target.value
        })
    }

    const getApi_Service = async () => {
        try {
            const res = await get_apis({ action: 'all-apis' });
            setApiService(res.data.api_services);
        } catch (error) {
            alert(error.response.data);
        }
    }

    const getService = async (id) => {
        try {
            const res = await get_service({ id, action: 'api' });
            setService(res.data.service);
            if (res.data.service.api_service_id) {
                await getServices_curl(res.data.service.api_service_id);
            }
        } catch (error) {
            alert(error.response.data);
        }
        dispatch(update_Line(false));
    }

    const updateConnection = async () => {
        try {
            service.action = 'api';
            const res = await update_service(service);
            alert(res.data.alert);
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
    }

    const getServices_curl = async (e) => {
        if (!e) {
            return (
                setServices_curl([]),
                setService({
                    ...service,
                    ['api_service_id']: '',
                    ['getapi_imei_id']: ''
                })
            )
        }
        try {
            const s = {
                id: e,
                action: 'service_api',
                type: 'IMEI'
            };
            const res = await get_apiService(s);
            setServices_curl(res.data.services);
        } catch (err) {
        }
    }

    const _customCheck = async () => {
        try {
            const res = await get_api_service({ id: service.getapi_imei_id });
            setCurl(res.data.service);
        } catch (error) {
            alert(error.response.data);
        }
    }

    return (
        <div>
            {
                !line &&
                <div>
                    <table className='table-add' >
                        <tbody>
                            <tr>
                                <td >Active:</td>
                                <td >
                                    <select
                                        className='outline-none pd-0-10p'
                                        name='isApi'
                                        onChange={(e) => handleChange(e)}
                                        value={service.isApi}
                                    >
                                        {
                                            active.map((a, k) => {
                                                return (
                                                    <option key={k} value={a.isActive}>
                                                        {a.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            {
                                service.isApi == "1" &&
                                <>
                                    <tr>
                                        <td className="fieldlabel">Connection Api: </td>
                                        <td className="fieldlabel">
                                            <select
                                                className='outline-none pd-0-10p'
                                                name='api_service_id'
                                                onChange={(e) => handleChange(e)}
                                                value={service.api_service_id || ''}
                                            >
                                                {
                                                    [{ id: '', name: '-Select api connect-' }, ...apiService].map((aps, k) => {
                                                        return (
                                                            <option key={k} value={aps.id}>
                                                                {aps.name}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            <div >
                                                <span className="color-red ft-size_11 ft-family-monospace">
                                                    {error.api_service_id}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fieldlabel">Api Services:</td>
                                        <td className="fieldlabel">
                                            <select
                                                className='outline-none pd-0-10p'
                                                name='getapi_imei_id'
                                                onChange={(e) => handleChange(e)}
                                                value={service.getapi_imei_id || ''}
                                            >
                                                {
                                                    [{ id: '', service_name: '-Select api service connect-', service_credit: '' }, ...services_curl].map((sc, k) => {
                                                        return (
                                                            <option key={k} value={sc.id}>
                                                                {sc.service_name} {sc.service_credit && `- - $${parseFloat(sc.service_credit).toFixed(2)}`}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                            <div >
                                                <span className="color-red ft-size_11 ft-family-monospace">
                                                    {error.getapi_imei_id}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fieldlabel">
                                            <button
                                                className="bg_313c42"
                                                onClick={() => _customCheck()}
                                            >
                                                Check custom need
                                            </button>
                                        </td>
                                        <td className="fieldlabel">
                                            {
                                                curlService && curlService.r_custom &&
                                                <>
                                                    <div >
                                                        <span className='color-black ft-size_10'>{'CUSTOM FIELD REQUIRED'}</span>
                                                    </div>
                                                    {
                                                        JSON.parse(curlService.r_custom).map((curl, key) => {
                                                            return (
                                                                <div key={key}>
                                                                    <div >
                                                                        <span className='color-black ft-size_11'>{'TYPE: '}</span>
                                                                        <span className='color-green ft-size_12'>{curl.fieldtype}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className='color-black ft-size_11'>{'NAME: '}</span>
                                                                        <span className='color-green ft-size_12'>{curl.fieldname}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                        </td>

                                    </tr>
                                </>
                            }
                            <tr>
                                <td className="fieldlabel">
                                    <button
                                        className="bg_313c42"
                                        onClick={() => updateConnection()}
                                    >
                                        Update
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div >
    )
}

export default ConnectApi