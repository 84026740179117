import React from 'react';
import { useSelector, useDispatch } from "react-redux";

const Line = () => {
    const line = useSelector((state) => state.loading.loading.line);

    return (
        <div>
            {line && <div className='line' />}
        </div>
    )
}

export default Line;